// export const FONT_FAMILY = "Open Sans, sans-serif"; //

export const FONT_FAMILY = "Open Sans";

export const FONT_FAMILY_GOTHAM = "Gotham, sans-serif";

//export const FONT_FAMILY = "Google Sans";

//export const FONT_FAMILY = "Merriweather";

export const SNACK_BAR_TIMEOUT = 4000;

export const ADMIN_BACKGROUND_API_TIMEOUT = 60000;
export const CANT_DIRECT_GO_MSG = "You can't visit this page directly";
export const GOOGLE_API_KEY = "";

export const ADMIN_HARDWARE_API_TIMEOUT = 10000;
//6Lf7L8YUAAAAABN3KEtkEfJGmGvEdSLyBCmUBUxZ

//Development
export const CAPTCHA_CLIENT_KEY = "6LfuvMYUAAAAAKPbaqRytdQEOtfdyjIWze2i-6yv";

//Production
//export const CAPTCHA_CLIENT_KEY = "6LecO8YUAAAAALa40ytQcflX9ULmXZiwvaCeYPSx";

export const PAYPAL_ENV = "sandbox";
//export const PAYPAL_ENV = "production";
export const PAYPAL_SANDBOX_CLIENT_ID =
  //"AdUE3elxM23Jy0-h_2y1bJGzCIZ5hfRdjTMDOdKgcgkl0GCVeNVEKu4MkF2ozFRylkR19fpKxuRjl35u";
  //"ATdv5okBRfmTxsophfBKy5sK5L7M39m46bwfX7my0MIM0xTV5gQXth9OfWW4thirOjg3dw3qb5AHcX6Y";
  "AaINZdBo0sbcBCqGD8QxpQQrSiz3jB_pgsfPNHdFHrK4_Sx0ppvdopR7PAZu0lizH46lZ1_vwc_i_n6e";

//rishi...
export const PAYPAL_PRODUCTION_CLIENT_ID =
  "AZJEf73TyPzNJIa4XmfkC5XXgoWTCbawWwOMbJ-yXgiH8Dmtj4OCHUqpi8vWr5ldneJTnatZR8Vi82tg";

export const PLAID_SANDBOX_PUBLIC_KEY = "948988c01c739086d7545357833c3d";
export const PLAID_DEVELOPMENT_PUBLICK_KEY = "948988c01c739086d7545357833c3d";
export const APP_PERMISSION = {
  USER_MANAGEMENT: "user_manage",
  VESSEL_MANANGEMENT: "vessel_manange",
  PLAN_MANAGMENT: "plans_manage",
  QUOTE_CREATION: "create_quote",
  QUOTE_APPROVAL: "approve_quote",
  AMOUNT_ADJUSTMENT: "amt_adj",
  INVENTORY_MANAGEMENT: "inv_manage",
};

export const VESSEL_STATUS = {
  LEAD_CREATED: "X-LL",
  OPP_CREATED: "X-OO",

  PROPOSAL_GENEATED: "X-PG",
  PROPOSAL_SENT: "X-PS",
  PROPOSAL_VIEWED: "X-PV",
  PROPOSAL_AUTHROIZED: "X-PZ",
  TOS_GENERATED: "X-TG",
  TOS_VIEWED: "X-TV",
  TOS_AUTHROIZED: "X-TZ",
  INVOICE_GENERATED: "X-IG",
  INVOICE_SENT: "X-IS",
  INVOICE_VIEWED: "X-IV",
  INVOICE_AUTHORIZED: "X-IZ",
  //invoice not paid but
  ACTIVATION_AUTHORIZED: "X-EZ",
  ACTIVATION_DONE: "A-XX",
};

export const getVesselStatusText = (status) => {
  const sts = status ? status.split("-")[1] : "";
  switch (sts) {
    case "LL":
      return "Lead Generated";

    case "OO":
      return "Opportunity Created";

    case "PG":
      return "Proposal Generated";

    case "PS":
      return "Proposal Sent";
    case "PV":
      return "Proposal Viewed";

    case "PZ":
      return "Proposal Authorized";

    case "TV":
      return "ToS Views";

    case "TZ":
      return "ToS Authorized";

    case "IS":
      return "Invoice Sent";

    case "IV":
      return "Invoice Viewed";

    case "IZ":
      return "Invoice Authorized";

    case "ES":
      return "Activation Authorized";

    default:
      return "";
  }
};
