import React from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  withRouter,
} from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
//import 'mdbreact/dist/css/mdb.css';
import "./assets/scss/mdb-free.scss";
import { MuiThemeProvider, createMuiTheme, Grid } from "@material-ui/core";
import NotFoundPage from "./containers/NotFoundPage/Loadable";
import GlobalStyle from "./global-styles";

// import SidebarHeader from "../SidebarAndHeader";

import Login from "./containers/login/index";
import Home from "./containers/dashboard/index";
import VesselAddOrUpdate from "./containers/vpnVesselReadUpdate";

import {
  primaryColor,
  primaryColorLight,
  colorPurple,
  colorDarkGray,
} from "./constants/Colors";


var logedInUser = {};
const theme = createMuiTheme({
  palette: {
    // type: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColor,

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#ffffff",
      main: colorPurple,
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffffff",
    },
    darkGrayCol: {
      // light: "#ffffff",
      main: colorDarkGray,
      // dark: will be calculated from palette.secondary.main,
      // contrastText: "#ffffff"
    },

    // error: will use the default color
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // useNextVariants: true,
    // fontFamily: [FONT_FAMILY].join(","),
    //fontSize: "4rem"
  },
  stepper: {
    iconColor: "green", // or logic to change color
  },
});

const checkAuth = () => {
  console.log("checking auth---");
  // logedInUser = session.getSessionData(keys.IS_LOGGED_IN);
  logedInUser = sessionStorage.getItem("islogedin");
  if (logedInUser) {
    return true;
  } else {
    return false;
  }
};

const AuthRoute1 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/home" }} />
      )
    }
  />
);

const PubRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !checkAuth() ? (
        <Component {...props} />
      ) : (
        // <Redirect to={{ pathname: '/vesselmanagement' }} />
        <Component {...props} />
      )
    }
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const AuthRoute2 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Redirect to={{ pathname: "/home" }} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

export default function App() {
  return (
    <div style={{
      scrollBehavior: "smooth"
    }}>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <AuthRoute1 exact path="/login" component={Login} />
          
          <AuthRoute2 exact path="/" component={Home} />
          <AuthRoute
            exact
            path="/home"
            component={Home}
          />

          <AuthRoute
            exact
            path="/vessel_details"
            component={VesselAddOrUpdate}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
        <GlobalStyle />
      </BrowserRouter>
    </MuiThemeProvider>
    </div>
  );
}
