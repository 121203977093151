export const primaryColor = "#008fb5"; //4A4A4A  008FBA  5892AD
export const primaryColorLight = "#CBF9F8";
export const primaryColorLightTwo = "#89cced";
export const colorPurple = "#342789";
export const colorMustard = "#FFD000";
export const pureWhite = "#FFFFFF";
export const colorPureBlack = "#000000";
export const colorAlertRed = "#f44336";
export const stateColorGreen = "#00DC81";
export const stateColorBlue = "#97CFE8";

export const colorMutedBlack = "#4A4A4A";

export const colorMutedGreen = "#2ECC71";

export const colorLightGrey = "#e0e0e0";

export const colorGrayLightt = "#D8D8D8";
export const colorGrayMoreLight = "#f4f4f4";
export const colorSemiGray = "#BCBCBC";
export const colorDarkGray = "#A9A9A9";
export const colorDarkerGray = "#616161";
export const orangeColor = "#F5BE64";

export const colorStatusOrange = "#FCC300";
export const colorStatusGreen = "#00D200";

export const colorPlanOne = "#D3995B";

export const colorPlanTwo = "#CBF9F8";
export const colorPlanTwoDark = "#5892AD";

export const colorPlanThree = "#F0FB00";
export const colorPlanThreeDark = "#B4A500";

export const coloriconK = "#2f3484";
export const darkPrimarycolor = "#25647E";

export const listPlanColorTheme = ["#D3995B", "#CBF9F8", "#F0FB00", "#9575CD"];
export const listPlanColorSecondary = [
  "#FFFFFF",
  "#5892AD",
  "#B4A500",
  "#FFFFFF"
];
export const listIsLight = [false, true, true, false];

export const caseStatusColorOrange = "#FFA500";
export const caseStatusColorGreen = "#32CD32";
export const caseStatusColorGray = "#C0C0C0";
export const caseStatusColorRed = "#DC143C";
