import React from "react"
import Header from "../../components/Header/index";

import Button from "@material-ui/core/Button";
import { MDBModal, MDBModalBody } from "mdbreact";

// import { withStyles } from "@material-ui/core/styles";
// import Dialog from "@material-ui/core/Dialog";
// import {
//     AppBar,
//     Toolbar,
//     IconButton,
//     Typography
// } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import CustomLoader from "../../components/Loders/CustomLoader";
import { colorAlertRed } from '../../constants/Colors';
// import MaterialDatePicker from "../../../components/MaterialDatePicker";
import InputMdb from '../../components/mdbreact/InputTypes/TextInput'
// import Radiobox from '../../../components/inputTypes/radioBox/singleRadio'
// import Moment from 'moment';
import H2 from '../../components/H2'
import './style.scss'
import CustomSnackbar from "../../components/snackBar/index";
// import { deepCopy } from "../../../utils/utils";
import { callPostApi, callGetApi } from "../../managers/api/ApiManager";
import { API_SEARCH_VESSEL, API_GET_ALL_VESSEL, API_DELETE_VESSEL } from "../../constants/ApiEndPoints";

// import "../../../components/DataTable/style.css";
// import "../../../components/DataTable/style.scss";
// import CheckboxCus from "../../../components/inputTypes/checkBox";

import DataTable from "../../components/DataTable";
import { deepCopy } from "../../utils/utils";
import DataTableFilter from "../../components/DataTable/filters";
import Moment from "moment";

import {
    BackgrounColorCard,
    pleaseWait,
    connRefInSerErr,
    authRole,
} from "../../utils/constants";

import OnLoadingAndFetching from "../../components/Loders/LodersAndPleasewait"; 

import AddOrUpdateVessel from "./AddOrUpdateVessel";

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            vs_nm: "",

            showLoader: false,
            loadingText: "",

            showSnackbar: false,
            snackbarVariant: "",
            snakBarMessage: "",
            position: "left",

            openModal: false,
            openModalText: "",

            isVesselExist: false,

            addOrUpdate: undefined,
            dataForUpdate: undefined,

            helperTextList: {
                vs_nm: "Please enter a valid Vessel Name that contain letters, numbers, dash and underscore only."
            },
            notErrorInFields: { vs_nm: true },

            disableSearch: true,

            fields: [
                // {
                //   id: "k4id",
                //   cssClass: "text-left",
                //   val: "",
                // },
                {
                id: "vs_nm",
                cssClass: "text-center ellipAndStWidth-customfieldService",
                actions: [
                    {
                    id: "vs_nm",
                    type: "link",
                    name: "vs_nm",
                    charLimit: 20,
                    ellipAndStWidth: true,
                    },
                ],
                },
                {
                id: "vpn_log",
                cssClass: "text-center",
                val: "",
                },
                {
                id: "vpn_pass",
                cssClass: "text-center",
                val: "",
                },
                {
                id: "vs_ip_add",
                cssClass: "text-center",
                val: "",
                },
                {
                id: "port_num",
                cssClass: "text-center",
                val: "",
                },
                {
                    id: "ser_add",
                    cssClass: "text-center",
                    val: "",
                },
                {
                name: "action",
                cssClass: "text-center",
                actions: [
                    {
                        id: 'edit',
                        type: 'button',
                        action: '',
                        cssClass: 'btn btn-rounded custom-button-icon-blue12 py-1 px-3',
                        faIcon: 'fas fa-edit custom-icon-size',
                        label: '',
                        hide: false,
                        disabled: false,
                        tooltip: 'Update Vessel'
                    },
                    {
                      id: "delete",
                      type: "button",
                      action: "",
                      cssClass: "btn btn-rounded custom-button-icon-red py-1 px-3",
                      faIcon: "fas fa-trash-alt custom-icon-size",
                      label: "",
                      hide: false,
                      disabled: false,
                      tooltip: "Delete Vessel"
                    }
                ],
                },
            ],
            dataLabals: [
                // { id: "k4id", label: "K4 ID", cssClass: "text-left" },
                { id: "vs_nm", label: "Vessel Name", cssClass: "text-center" },
                { id: "vpn_log", label: "VPN Login", cssClass: "text-center" },
                { id: "vpn_pass", label: "VPN Password", cssClass: "text-center" },
                { id: "vs_ip_add", label: "Vessel IP Address", cssClass: "text-center" },
                { id: "port_num", label: "Port Number", cssClass: "text-center" },
                { id: "ser_add", label: "Server Address", cssClass: "text-center" },
                { label: "Action", cssClass: "text-center" },
            ],
            data: "",

            filteredRecords: 1,
            totalCount: 0,
            recordsPrePage: 10,
            page: 1,

            // searchOn: [
            //     { name: "", label: "Filter On" },
            //     { name: "k4id", label: "K4 ID" },
            //     { name: "vs_nm", label: "Vessel Name" },
            //     { name: "vs_st", label: "Status" },
            //     // { name: "pendAct", label: "Activations Pending" },
            //     { name: "vsat", label: "Pending VSAT Activation" },
            //     { name: "lte", label: "Pending LTE Activation" },
            //     { name: "voip", label: "Pending VoIP Activation" },
            //     { name: "hardware", label: "Pending Hardware Activation" },
            //     // { name: "fld_srv", label: "Field Service Pending" },
            //     // { name: "sales", label: "Sales Person" },
            //     // { name: "cnts", label: "Primary Contact" },
            // ],
            // filters: {},
            // exportHeaders: [
            //     { key: "k4id", value: "K4 ID" },
            //     { key: "vs_nm", value: "Vessel Name" },
            //     { key: "vs_st", value: "Status" },
            //     // { key: "sales", value: "Sales Person" },
            //     // { key: "cnts", value: "Primary Contact" },
            // ],

            delVesselModal: false, delVesselText: undefined, delVesselData: undefined,
            dataCopy: [],
            vesselDict: {},
        }
    }

    signOut = () => {
        sessionStorage.clear();
        this.props.history.push("/login")
    }

    gettAllVessels = (e, mappingKey) => {

        if(mappingKey !="refresh"){
            this.setState({
                showLoader: true,
                loadingText: "Searching vessels... please wait"
            })
        }
        
        callGetApi(
            "Getting ip and port component",
            "Getting ip and port",
            API_GET_ALL_VESSEL
        )
            .then((res) => {
                console.log("res all vessels--------", res)
                if (res && (res.status === 200) && (res.message === "OK")) {
                    this.setState({
                        showLoader: false,
                    }, ()=>{
                        let tempData = []
                        for(let tempI=0; tempI<res.data.length; tempI++){
                            if(res.data[tempI].is_rem === 0){
                                tempData.push(res.data[tempI])
                            }
                        }
                        this.fnSetResult(tempData);
                    })
                }
                else {
                    alert("Error in getting vessels---" + JSON.stringify(res));
                    this.setState({
                        showLoader: false
                    })
                }
            })
            .catch(err => {
                console.log("error-----", err)
                alert("Error in getting vessels---" + JSON.stringify(err));
                this.setState({
                    showLoader: false
                })
            })

    }

    componentDidMount(){
        this.gettAllVessels();
    }

    updateVessel = (data) =>{
        console.log("data---", data)

        this.setState({
            addOrUpdate: "update",
            dataForUpdate: data,
            isVesselExist: true
        })
    }

    delVessel = (data, mappingKey) =>{
        if(mappingKey === "No"){
            this.setState({
                delVesselModal: false,
                delVesselText: undefined,
                delVesselData: undefined,
                isVesselExist: false
            })
        }
        else if(data){
            this.setState({
                delVesselModal: true,
                delVesselText: 'Do you want to delete ' + data["vs_nm"] + " ?",
                delVesselData: data
            })
        }
    }

    fnDelVessel = () =>{
        this.setState({
            showLoader: true,
            loadingText: "Deleting Vessel... please wait"
        })

        let payload = {
            vs_nm: this.state.delVesselData.vs_nm,
            is_rem: 1
        }
        let messageQuote = 'Vessel successfully deleted.'; let isErrorSuccessInfo = 'success';

        callPostApi("Delete vessel Componenet", "Delete vessel", API_DELETE_VESSEL, payload)
            .then((res) => {
                console.log("res---", res)
                if (res && (res.status === 200) && (res.message === "OK")) {
                    this.setState({
                        showSnackbar: true,
                        snackbarVariant: isErrorSuccessInfo,
                        snakBarMessage: messageQuote,
                        position: this.state.position,
                        showLoader: false,

                        delVesselModal: false,
                        delVesselText: undefined,
                        delVesselData: undefined,
                        isVesselExist: false
                        
                    }, () => {
                        // this.props.updatePlanList();
                        // setTimeout(() => {
                        //     this.props.onClose();
                        // }, 1000)
                        this.gettAllVessels();
                    })
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                    alert("Error in deleting vessel details---" + JSON.stringify(res));
                }
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                alert(("Error in deleting vessel details---" + JSON.stringify(err)));
            })
    }

    fnSetResult = (result) => {
        console.log("vessels data---", result);
        const providers = result.map((d, init) => {
          return deepCopy(this.state.fields).map((f) => {
            if (f == "clsrdt" || f == "cr_at" || f == "lstdt") {
              const date = Moment(d[f]).format("DD-MMM-YYYY");
              d[f] = {
                type: "date",
                cssClass: "text-center",
                val: date,
                id: "created_at",
              };
              this.state.date = date;
            } else if (f === "sales" && d[f] != undefined) {
              d[f] = d[f].name;
            } else if (f === "cnts" && d[f][0] != undefined) {
              d[f] = `${d[f][0].name} (${d[f][0].role})`;
            } else if (f === "pp") {
              if (d[f] != undefined) {
                d[f] = {
                  type: "number",
                  cssClass: "text-center",
                  val: `${d[f]}%`,
                  id: "pp",
                };
              } else {
                d[f] = {
                  type: "number",
                  cssClass: "text-center",
                  val: `${0}%`,
                  id: "pp",
                };
              }
            } else if (f === "ex_rv") {
              let value;
              if (d[f] != undefined) {
                value = `$ ${d[f]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
              } else {
                value = `$0`;
              }
    
              d[f] = {
                type: "number",
                cssClass: "text-center",
                val: value,
                id: "ex_rv",
              };
            } else if (f === "vs_st") {
              d[f] = {
                type: "string",
                cssClass: "text-center",
                val: `${d[f]}`,
                id: "vs_st",
              };
            }
    
            if (typeof f == "object") {
              if (f.actions) {
                f.actions = f.actions.map((act, index) => {
                  if (act.type == "link" && act.id == "vs_nm") {
                    act.action = this.updateVessel.bind(this, d, init)
                    act.label = d[act.id];
                  } else if (act.type == "button" && act.id == "edit") {
                    act.action = this.updateVessel.bind(this, d, init);
                  } else if (act.type == "button" && act.id == "delete") {
                    act.action = this.delVessel.bind(this, d, init);
                    // act.action = this.fnConfirmPopup.bind(this, {
                    //   id: d.source_id,
                    //   type: d.type,
                    //   source: "provider",
                    //   message: `Do you want to delete ${d["vs_nm"]} ?`,
                    //   accept: () => this.fnReject(d, init),
                    // });
                    console.log("ddd", act.action);
                  } else if (act.type === "button" && act.id === "assignFieldEng") {
                    act.action = this.fnassignFieldEng.bind(this, d, init)
                  }
                  return act;
                });
    
                return f;
              } else {
                if (f.id == "type") {
                  f.val = d[f.name] ? d[f.name] : "--";
                } else if (f.id === "sales" && d[f.id] != undefined) {
                  f.val = d.sales.name;
                } else if (f.id === "field_eng") {
                  f.val = (d && d.field_eng && d.field_eng[0] && d.field_eng[0].name) ? d.field_eng[0].name : "---"
                } else if (f.id === "cnts" && d[f.id][0] != undefined) {
                  f.val = `${d[f.id][0].name} (${d[f.id][0].role})`;
                } else if (f.id === "k4id" && d[f.id] != undefined) {
                  f.val = d.k4id;
                } else if (f.id === "vs_st" && d[f.id] != undefined) {
                  f.val = d.vs_st;
                }

                else if (f.id === "vpn_log" && d[f.id] != undefined) {
                    f.val = d.vpn_log;
                }
                else if (f.id === "vpn_pass" && d[f.id] != undefined) {
                    f.val = d.vpn_pass;
                }
                else if (f.id === "vs_ip_add" && d[f.id] != undefined) {
                    f.val = d.vs_ip_add;
                }
                else if (f.id === "port_num" && d[f.id] != undefined) {
                    f.val = d.port_num.toString();
                }
                else if (f.id === "ser_add" && d[f.id] != undefined) {
                    f.val = d.ser_add;
                }
                // else if (f.id === "pendAct" && d[f.id] != undefined) {
                //   f.val = d.pendAct ? d.pendAct :  d.pendAct === 0 ? d.pendAct : '---';
                // }
                else if (
                  (f.id === "vsat")
                ) {
                  if (
                    (f.id === "vsat") &&
                    (d.pendAct &&
                      (d.pendAct.vsat != undefined) && (typeof (d.pendAct.vsat) != "object"))
                  ) {
                    f.val = d.pendAct
                      ? d.pendAct.vsat
                      : d.pendAct.vsat === 0
                        ? d.pendAct.vsat
                        : "---";
                  }
                  else {
                    f.val = "---"
                  }
                } else if (
                  (f.id === "lte")
                ) {
    
                  if (
                    (f.id === "lte") &&
                    (d.pendAct &&
                      (d.pendAct.lte != undefined) && (typeof (d.pendAct.lte) != "object"))
                  ) {
                    f.val = d.pendAct
                      ? d.pendAct.lte
                      : d.pendAct.lte === 0
                        ? d.pendAct.lte
                        : "---";
                  }
                  else {
                    f.val = "---"
                  }
                } else if (
                  (f.id === "voip")
                ) {
    
                  if ((f.id === "voip") &&
                    (d.pendAct &&
                      (d.pendAct.voip != undefined) && (typeof (d.pendAct.voip) != "object"))) {
                    f.val = d.pendAct
                      ? d.pendAct.voip
                      : d.pendAct.voip === 0
                        ? d.pendAct.voip
                        : "---";
                  }
                  else {
                    f.val = "---"
                  }
    
                } else if (
                  (f.id === "hardware")
                ) {
    
                  if (
                    (f.id === "hardware") &&
                    (d.pendAct &&
                      (d.pendAct.hardware != undefined) && (typeof (d.pendAct.hardware) != "object"))
                  ) {
                    f.val = d.pendAct
                      ? d.pendAct.hardware
                      : d.pendAct.hardware === 0
                        ? d.pendAct.hardware
                        : "---";
                  }
                  else {
                    f.val = "---"
                  }
                }
                // else if (f.id === "fld_srv" && d[f.id] != undefined) {
                //   f.val = d.fld_srv ? d.fld_srv.required : '---';
                // }
                return f;
              }
            }
    
            if (d[f]) {
              return d[f];
            } else {
              return "";
            }
          }, this);
        }, this);
        setTimeout(() => {
          this.setState({
            data: providers,
            filteredRecords: result.filteredRecords,
            dataCopy: providers
          }, () => {
              this.fnCreateDict(result);
          });
        }, 10);
      }

    handleChange = (e) => {
        const { name, value, id } = e.target;
        // value = value.trim();

        if (name === "isUnlimited") {
            if (value === "true") {
                this.setState({
                    [name]: "false"
                })
            }
            else {
                this.setState({
                    [name]: "true"
                })
            }
        }
        else {
            this.setState(
              {
                [name]: value,
              },
              () => {
                this.validateField(name, value);
                if (value === "") {
                  this.setState({
                    data: [...this.state.dataCopy],
                  });
                } else {
                  let arr = Object.keys(this.state.vesselDict).map(
                    (obj, ind) =>
                    {
                      if (obj.toLowerCase().includes(value.toLowerCase())) {
                        let nn = this.state.data.find(obj => !obj);
                        if(!nn) {
                            return this.state.dataCopy[ind];
                        } else {
                            return this.state.data[ind];
                        }
                      }
                    }
                  );
                  this.setState({
                    data: arr,
                  });
                }
              }
            );
        }
    }

    validateField = (fieldName, value) => {
        let { notErrorInFields, disableSearch } = this.state;
        switch (fieldName) {
            case "vs_nm":
                notErrorInFields[
                    fieldName
                ] = /^([A-Za-z0-9\-\_\' ']+)$/.test(
                    value
                );
                break;
            default:
                break;
        }
        console.log("notErrorInFields---", notErrorInFields)

        if (notErrorInFields.vs_nm) {
            disableSearch = true;
        } else {
            disableSearch = false;
        }

        this.setState({
            notErrorInFields: notErrorInFields, disableSearch
        });
    };


    handleSave = (e) => {
        console.log("On vessel search---------")
        e.preventDefault();

        this.setState({
            showLoader: true,
            loadingText: "Searching vessel... please wait"
        })

        let payload = {
            vs_nm: this.state.vs_nm
        }

        callPostApi("Search Vessel component", "Search vessel", API_SEARCH_VESSEL, payload)
            .then((res) => {
                console.log("res of vessel---", res, res.status)
                if (res && res.status === 200) {

                    if (res.data) {
                        this.setState({
                            // showSnackbar: true,
                            // snackbarVariant: isErrorSuccessInfo,
                            // snakBarMessage: messageQuote,
                            // position: this.state.position,
                            showLoader: false,

                            addOrUpdate: "update",
                            dataForUpdate: res.data,
                            isVesselExist: !this.state.isVesselExist

                        }, () => {

                            // this.props.history.push({
                            //     pathname: '/vessel_details',
                            //     // search: '?query=abc',
                            //     state: { detail: res.data }
                            // })

                            // this.props.updatePlanList();
                            // setTimeout(() => {
                            //     this.props.onClose();
                            // }, 500)
                        })
                    }
                    else {
                        this.setState({
                            // showSnackbar: true,
                            // snackbarVariant: isErrorSuccessInfo,
                            // snakBarMessage: messageQuote,
                            // position: this.state.position,
                            showLoader: false,

                            addOrUpdate: "add",
                            dataForUpdate: undefined,
                            // isVesselExist: !this.state.isVesselExist
                            openModal: !this.state.openModal
                        }, () => {

                            // this.props.history.push({
                            //     pathname: '/vessel_details',
                            //     // search: '?query=abc',
                            //     state: { detail: res.data }
                            // })

                            // this.props.updatePlanList();
                            // setTimeout(() => {
                            //     this.props.onClose();
                            // }, 500)
                        })
                    }
                }
                else {
                    if (res.message === "Please check Vessel Name.") {
                        this.setState({
                            showLoader: false,
                            openModal: !this.state.openModal,
                            addOrUpdate: "add",
                            dataForUpdate: undefined,
                        })
                    }
                    else {
                        this.setState({
                            showLoader: false,
                        })
                        alert("Error in vessel search---" + JSON.stringify(res));
                    }
                }
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                alert(("Error in vessel search---" + JSON.stringify(err)));
            })
    }

    addNewVessel = () => {
        this.setState({
            isVesselExist: !this.state.isVesselExist,
            openModal: !this.state.openModal
        })
    }

    closeOpenModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }

    handleSnackBarClose = () => {
        console.log("am i been called????");
        this.setState(
          {
            finalMessage: "",
            showSnackbar: false,
          },
          () => {
            //window.location.reload();
          }
        );
      };

    fnCreateDict = (value) => {
        let list = {};
        value.map((obj, index) => {
            list[obj.vs_nm] = index;
        })
        this.setState({
            vesselDict: list,
        })
    }

    render() {
        console.log("dis---", this.state.disableSearch)
        return (
            <div style={{height:"100%"}}>
                <Header signOut={this.signOut} />
                <div className="page-content mt-10 pl-10 pr-10" >
                    {/* <h3 className="tbh-head-text H1-tncdg6-0 dwHTCh" style={{ width: "100%" }} >
                        Search Vessel
                    </h3> */}

                    <div >

                        <form onSubmit={this.handleSave}>
                            <br /><br />
                            <div className="row">
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 " >
                                </div>

                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 " >

                                    <div className="row">
                                        {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 " >
                                        <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                            Vessel Name <span style={{ color: `${colorAlertRed}` }}>*</span>
                                        </H2>
                                    </div> */}
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 " >
                                            <InputMdb
                                                type='text'
                                                name='vs_nm'
                                                id='vs_nm'
                                                value={this.state.vs_nm}
                                                autoFocus={true}
                                                placeholder='Enter Vessel Name'
                                                minLength='1'
                                                handleChange={this.handleChange}
                                                title=''
                                                required={true}
                                                maxLength='100'
                                                // disabled={addOrUpdate === "read" ? true : false}
                                                style={{ marginBottom: "6px" }}
                                                isValidOrInvalid={this.state.notErrorInFields['vs_nm'] ? '' : 'isInvalid'}
                                                isValidOrInvalidMessage={this.state.helperTextList['vs_nm']}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 " >
                                </div>
                            </div>

                            <br />
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    style={{ 
                                        backgroundColor: this.state.disableSearch ? "#008fb5" : "lightgrey", 
                                        color: "white" ,
                                        cursor: this.state.disableSearch ? "pointer" : "not-allowed"
                                    }}
                                    // onClick={this.handleSave}
                                    type="submit"
                                    disabled={!this.state.disableSearch}
                                >
                                    Search Vessel
                                </Button>
                            </div>
                        </form>

                    </div>

                    <div style={{margin:"15px 35px"}}>
                        {
                            this.state.data ? (
                                <DataTable
                                  headers={this.state.dataLabals}
                                  data={this.state.data}
                                  filteredRecords={this.state.totalCount}
                                  page={this.state.page}
                                  fnSetRecordsPrePage={this.fnSetRecordsPrePage}
                                  fnSetPage={this.fnSetPage}
                                  sorting={this.sorting}
                                  recordsPerPage={this.state.recordsPrePage}
                                  hidePagination = {true}
                                  hideSorting = {true}
                                />
                              ) : (
                                    <OnLoadingAndFetching
                                      L_MarginTop="50px"
                                      PL_Color={BackgrounColorCard}
                                      L_Height="50px"
                                      L_Width="50px"
                                      P_Heading={"Fetching Vessels..."}
                                      P_MarginLeft="5px"
                                      P_FontSize="16px"
                                      P_MarginTop="5px"
                                      background="transparent"
                                    />
                                  )
                            }
                    </div>

                    <MDBModal isOpen={this.state.openModal} centered>
                        <MDBModalBody>
                            <div className="col-12 p-3 text-center custom-popup-border">
                                <i className=" fa fa-question-circle custom-popup-icon"></i>
                                <h4 className="mb-4 mt-4">
                                    {/* {this.state.openModalText} */}
                                    <b>{this.state.vs_nm}</b> does not exist. Click <b>Yes</b> to add a new Vessel with this name.
                            </h4>
                                <button
                                    type="button"
                                    className="btn btn-primary custom-popup-btn"
                                    onClick={this.addNewVessel}
                                >
                                    Yes
                            </button>
                                <button
                                    type="button"
                                    className="btn btn-white custom-popup-btn"
                                    onClick={this.closeOpenModal}
                                >
                                    No
                            </button>
                            </div>
                        </MDBModalBody>

                    </MDBModal>

                    <MDBModal isOpen={this.state.delVesselModal} centered>
                        <MDBModalBody>
                            <div className="col-12 p-3 text-center custom-popup-border">
                                <i className=" fa fa-question-circle custom-popup-icon"></i>
                                <h4 className="mb-4 mt-4">
                                    {this.state.delVesselText}
                                    {/* <b>{this.state.vs_nm}</b> does not exist. Click <b>Yes</b> to add a new Vessel with this name. */}
                            </h4>
                                <button
                                    type="button"
                                    className="btn btn-primary custom-popup-btn"
                                    onClick={this.fnDelVessel}
                                >
                                    Yes
                            </button>
                                <button
                                    type="button"
                                    className="btn btn-white custom-popup-btn"
                                    onClick={(e) =>{this.delVessel(undefined, "No")} }
                                >
                                    No
                            </button>
                            </div>
                        </MDBModalBody>

                    </MDBModal>


                    {this.state.isVesselExist ? (
                        <AddOrUpdateVessel
                            selectedPlanObj={"Vessel"} // selected plan view
                            addOrUpdate={this.state.addOrUpdate} // add or update key
                            dataForUpdate={this.state.dataForUpdate} // data for update
                            // updatePlanList={this.updatePlanList} // update plan list
                            // planCateg={this.state.planCateg} // plan category list
                            // all_regions={this.state.all_regions} // regions list
                            vs_nm={this.state.vs_nm}
                            gettAllVessels = {this.gettAllVessels}
                            onClose={() => {
                                this.setState({
                                    addPlansState: false,
                                    // selectedPlanObj: null,
                                    addOrUpdate: undefined,
                                    dataForUpdate: undefined,
                                    isVesselExist: !this.state.isVesselExist
                                });
                            }}
                        />
                    ) :
                        null
                    }

                    {this.state.showLoader ? (
                        <CustomLoader
                            showLoader={this.state.showLoader}
                            loadingText={this.state.loadingText}
                        />
                    ) : null}

                    <CustomSnackbar
                        showSnackbar={this.state.showSnackbar}
                        variant={this.state.snackbarVariant}
                        message={this.state.snakBarMessage}
                        onClose={this.handleSnackBarClose}
                        position={this.state.position}
                    />

                </div>
            </div>
        )
    }
}

export default Home;