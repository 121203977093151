import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
} from "mdbreact";
import LogoColor from "../../assets/images/K4_Logo.png";
import "./style.scss";
import Avatar from "@material-ui/core/Avatar";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { makeStyles } from "@material-ui/core/styles";

import { deepOrange, deepPurple } from "@material-ui/core/colors";
// import {
//   getSessionData,
//   clearSession,
// } from "../../../managers/session/SessionManager";
// import { USER_DATA } from "../../../constants/SessionKeys";
// import HeaderDropDown from "./HeaderDropDown";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
// import DistributionPartnerList from "../../DistributionPartner/DistributionPartnerList";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      distPartnerFlag : false,
    };
    this.handleRoute = this.handleRoute.bind(this);
  }

  handleRoute() {
        console.log("check this::::",window.location.pathname)
        if(window.location.pathname === "/distributionPartner") {
          //window.location.reload()
        }
        else {
          this.props.onActive("/distributionPartner");
          this.setState({distPartnerFlag: true})
        }
        
  }

  signOut = () =>{
    this.props.signOut()
  }

  render() {
    const userData = {
      FName: "N",
      LName: "A"
    }
    // getSessionData(USER_DATA);
    console.log("Logged-In User:::::", userData);
    const firstName = userData
      ? userData.FName
        ? userData.FName.charAt(0)
        : ""
      : "";
    const lastName = userData
      ? userData.LName
        ? userData.LName.charAt(0)
        : ""
      : "";

    return (
      <MDBNavbar
        className="top-header custome-navbar-header"
        expand="md"
        style={{ background: "ghostwhite" }}
      >
        <MDBNavbarBrand className="custom-navbar">
          <img
            style={{ marginTop: "2px", marginLeft: "0px", height:"45px", width:"80px" }}
            src={LogoColor}
            width="170px"
          ></img>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>{/* <TopSearch {...this.props}  /> */}</MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            {/* <ProfileMenu {...this.props} /> */}
            {firstName && lastName && (
              <Grid container justify="center" alignItems="center">
                {/* <Grid item>
                  <Tooltip title= "Distribution Partner" style={{marginRight:"10px"}}>
                  <IconButton 
                    color="primary" 
                    aria-label="Distribution Partner" 
                    component="span"
                    onClick = {e => {
                      this.handleRoute()
                    }}
                    >
                      <GroupWorkIcon style={{fontSize:"48px"}} />    
                  </IconButton>
                  </Tooltip>
                </Grid> */}
                
                {/* <Grid item>
                  <Avatar
                    style={{ background: "rgb(52,39,137)" }}
                    className="Avatar"
                  >
                    {`${firstName}${lastName}`}
                  </Avatar>
                </Grid> */}

                {/* <Grid item>
                  <HeaderDropDown />
                </Grid> */}
              </Grid>
            )}
            {/* {this.state.distPartnerFlag ? 
              <DistributionPartnerList 
                
              />
              :
              null
            } */}
            <i
              style={{
                color: "rgb(52,39,137)",
                fontSize: "25px",
                // marginTop: "7px",
                marginLeft: "10px",
                cursor:"pointer"
              }}
              onClick={this.signOut}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="fas fa-sign-out-alt"
              title = "Sign Out"
            ></i>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}
export default Header;
