import axios from "axios";
import { logData } from "./LogManager";
import { ACCESS_TOKEN } from "../../constants/SessionKeys";
import * as SessionManager from "../session/SessionManager";
// import { PAYPAL_APIs } from "../../constants/ApiEndPoints";

//axios.defaults.timeout = 10000;
// var CancelToken = axios.CancelToken;
// var Call1 = CancelToken.source();
// const instance = axios.create();
// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
//instance.defaults.timeout = 10000;

//Api for uploading files
export const uploadFile = async (
  componentName,
  tag,
  url,
  payload
 ) => {
  return new Promise(async (resolve, reject) => {
    console.log("qweqwe")
  try {
    logData(tag, {
      COMPONENT_NAME: componentName,
      API_URL: url,
      HEADER: getHeaderUpload(),
      BODY: JSON.stringify(payload),
    });
  
  const apiResponse = await axios.post(
  url,
  payload,
  {
  headers: getHeaderUpload(),
  }
  );
 
  resolve(apiResponse.data);
  } catch (error) {
  //parseError(tag, error, reject);
  console.log("asdasdasd",error)
  reject();
  }
  });
 };

export const callPostApi = async (componentName, API_TAG, URL, bodyParams) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    let apiResponse = await axios.post(URL, JSON.stringify(bodyParams), {
      // withCredentials: true
      headers: getHeader(),
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};

export const callPatchApi = async (componentName, API_TAG, URL, bodyParams) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    let apiResponse = await axios.patch(URL, JSON.stringify(bodyParams), {
      // withCredentials: true
      headers: getHeader(),
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};

export const callDeleteApi = async (componentName, API_TAG, URL) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: {},
    });

    let apiResponse = await axios.delete(URL, {
      // withCredentials: true
      headers: getHeader(),
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};

export const callPostApiWithoutToken = async (
  componentName,
  API_TAG,
  URL,
  bodyParams
) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    console.log(JSON.stringify(bodyParams));

    let apiResponse = await axios.post(URL, JSON.stringify(bodyParams), {
      // withCredentials: true
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return null;
  }
};

export const callGetApi = async (componentName, API_TAG, URL) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: {},
    });
    let apiResponse = await axios.get(URL, {
      headers: getHeader(),
    });
    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );

    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );
    return e;
  }
};

export const callPayPalPlanCreationApi = async (
  API_TAG,
  oneTimePaymentAmount,
  periodicPaymentAmt,
  timePeroid
) => {
  // let apiUrl = PAYPAL_APIs.PLAN_CREATION;
  let apiUrl = ""

  let bodyParams = {
    product_id: "PROD-52T73491PY497435T",
    name: "Plan Payment",
    description: "Plan Payment",
    billing_cycles: [
      // {
      //   "frequency": {
      //     "interval_unit": "MONTH",
      //     "interval_count": 1
      //   },
      //   "tenure_type": "TRIAL",
      //   "sequence": 1,
      //   "total_cycles": 1,
      //   "pricing_scheme": {
      //     "fixed_price": {
      //       "value": ""+oneTimePaymentAmount,
      //       "currency_code": "USD"
      //     }
      //   }
      // },
      {
        frequency: {
          interval_unit: "MONTH",
          interval_count: 1,
        },
        tenure_type: "REGULAR",
        sequence: 1,
        total_cycles: Number(timePeroid),
        pricing_scheme: {
          fixed_price: {
            value: "" + periodicPaymentAmt,
            currency_code: "USD",
          },
        },
      },
    ],
    payment_preferences: {
      auto_bill_outstanding: true,
      setup_fee: {
        value: "" + oneTimePaymentAmount,
        currency_code: "USD",
      },
      setup_fee_failure_action: "CONTINUE",
      payment_failure_threshold: 3,
    },
    taxes: {
      percentage: "0",
      inclusive: false,
    },
  };
  logData(API_TAG, {
    COMPONENT_NAME: "",
    API_URL: apiUrl,
    HEADER: getPaypalHeader(),
    BODY: JSON.stringify(bodyParams),
  });
  return new Promise((resolve, reject) => {
    axios
      .post(apiUrl, JSON.stringify(bodyParams), {
        headers: getPaypalHeader(),
      })
      .then((res) => {
        logData(
          API_TAG,
          JSON.stringify({
            response: res.data,
          })
        );
        resolve(res.data);
      })
      .catch((err) => {
        logData(
          API_TAG,
          JSON.stringify({
            error: err,
          })
        );
        reject(err);
      });
  });
};

export const getHeaderUpload = () => {
  let accessToken = SessionManager.getSessionData(ACCESS_TOKEN);

  if (accessToken === null || accessToken === undefined) {
    return {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      Accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data;`,
    };
  } else {
    return {
      Accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data;`,
      Authorization: "Bearer " + accessToken,
      //timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  // return {
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // };
};


export const getHeader = () => {
  let accessToken = SessionManager.getSessionData(ACCESS_TOKEN);

  if (accessToken === null || accessToken === undefined) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      //timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  // return {
  //   Accept: "application/json",
  //   "Content-Type": "application/json"
  // };
};

export const getPaypalHeader = () => {
  return {
    "Content-Type": "application/json",
    Authorization:
      "Bearer A21AAFIx9xZhVslNZQwV_HBY7emmsRDL5o1p86c8UxHcq7RbThs4lE0nwHQL1Z2iudJ2Jec3j-gu5WOkgWjUde45Zx60JeATA",
  };
};

export const callPutApi = async (
  componentName,
  API_TAG,
  URL,
  id,
  bodyParams
) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    let apiResponse = await axios.put(
      `${URL}/${id}`,
      JSON.stringify(bodyParams),
      {
        headers: getHeader(),
      }
    );

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (apiResponse.data.status == 999) {
      SessionManager.clearSession();
      alert(apiResponse.data.message);
      window.location.reload();
      return null;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return e;
  }
};
