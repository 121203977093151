import styled from 'styled-components';
// import { ButtonAndBackgroundColor } from '../utils/constants';

const H2 = styled.h2`
  font-size: 15px !important;
  // color: #455a64 !important;
  color: black !important;
  font-family: Roboto, sans-serif;
  font-weight: 400;

  margin-bottom: 3px;
  /* background: #414C5D; */
`;

export default H2;
