export const API_BASE_URL = "http://52.188.68.136:5000/";

// export const API_BASE_URL = "http://localhost:5000/";

// export const API_BASE_URL = "https://d7.k4mobility.com/api/";


export const API_LOGIN = API_BASE_URL + "login";

export const API_ADD_OR_UPDATE_VESSEL = API_BASE_URL + "vpninfo";

export const API_SEARCH_VESSEL = API_BASE_URL + "getvpninfo";

export const API_DELETE_VESSEL = API_BASE_URL + "deletevpninfo";

export const API_GET_AVAILABLE_IPPORT_VESSEL = API_BASE_URL + "getipports";

export const API_GET_ALL_VESSEL = API_BASE_URL + "getallvpns";




export const   API_EXPORT_INVENTORY_DETAILS = API_BASE_URL + "";
export const   API_EXPORT_LTE_PROVIDER_DETAILS = API_BASE_URL + "";
export const   API_EXPORT_VESSEL_DETAILS = API_BASE_URL + "";
export const   FILE_API_URL = API_BASE_URL + "";

export const   VPN_FILE_API = API_BASE_URL + "getvpnfile"
