/**
 *
 * DataTable
 *
 */

import React, { Fragment } from "react";
import leftArrow from "../../assets/img/left-arrow-angle.png";
import rightArrow from "../../assets/img/right-arrow-angle.png";
import { deepCopy } from "../../utils/utils";
import { MDBTooltip, MDBBtn } from "mdbreact";
import "./style.scss";
import CustomButton from "../../components/mdbreact/Buttons";
// import $ from 'jquery';
// import Switch from "react-switch";
import StarRatings from "react-star-ratings";
import $ from "jquery";
import {
  primaryColor,
  colorLightGrey,
  colorGrayMoreLight,
  colorGrayLightt,
  pureWhite,
} from "../../constants/Colors";
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Slide,
  AppBar,
  IconButton,
  Grid,
  Popover,
  Typography,
  Checkbox,
} from "@material-ui/core";
import "./style.css";
//import Tooltip from '@material-ui/core/Tooltip';
import "../mdbreact/InputTypes/TextInput/inputIndex.css";

const Status = [
  { id: "X-LL", value: "Lead" },
  { id: "X-OO", value: "Opportunity" },
  { id: "X-PG", value: "Proposal Generation (being edited/created)" },
  { id: "X-PS", value: "Proposal and ToS/link Sent to customer" },
  { id: "X-PV", value: "Proposal/link Viewed by customer" },
  { id: "X-PZ", value: "Proposal/link authoriZed by customer" },
  { id: "X-TV", value: "ToS Viewed by customer" },
  { id: "X-TZ", value: "ToS authoriZed by customer" },
  { id: "X-IG", value: "Invoice Generation (being edited/created)" },
  { id: "X-IS", value: "Invoice and ToS/link Sent to customer" },
  { id: "X-IV", value: "Invoice/link Viewed by customer" },
  { id: "X-IZ", value: "Invoice/link authoriZed by customer" },
  { id: "X-EZ", value: "Invoice not paid but activation authorized " },
  { id: "A-XX", value: "Activation Done, at least one plan active" },
];

const Probability = [
  { id: "<50%", value: "Lead" },
  { id: "50%", value: "Converted to Opportunity" },
  { id: "60%", value: "Proposal Generated" },
  { id: "70%", value: "Proposal Viewed" },
  { id: "80%", value: "Proposal Accepted" },
  { id: "90%", value: "Invoice Authorized" },
  { id: "100%", value: "Payment Done" },
];

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSort: "default",
      perPage: [5, 10, 15, 25],
      recordsPerPage: this.props.recordsPerPage || 5,
      page: 1,
      totalPages: 1,
      totalColumns: this.props.headers != "" ? this.props.headers.length : "",
      popoverValue: "",
      anchorEl: null,
      voipcount: 1,
      data: []
    };
    this.handleClose = this.handleClose.bind(this);
  }

  fnSetRecordsPrePage = (e) => {
    let a = e.target.value;
    let page = 1;
    this.setState({ recordsPerPage: a }, function () {
      console.log("page state::::::::::", this.state.recordsPerPage);
      this.props.fnSetRecordsPrePage(page, this.state.recordsPerPage);
    });
  };

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  fnGetRecords = (page) => {
    console.log("fnGetRecords ::::::::: page", page);
    this.props.fnSetPage(page);
  };

  handleToggleChange = (e) => {};

  headerInfo(e, value) {
    console.log("check Value", value);
    this.setState({ popoverValue: value, anchorEl: e.currentTarget });
  }
  handleClose() {
    this.setState({ anchorEl: null });
  }

  sortData = (e, column = "") => {
    console.log("check values e and clmn", e.target, column);
    //e.preventDefault();
    let pcls = "fa-sort";
    let cls = "fa-sort";
    let sorting = { orderBy: "", order: "ASC" };

    $(e.target)
      .parents("th")
      .siblings()
      .each((i, th) => {
        $(th)
          .find("i.sort")
          .removeClass("fa-sort-down")
          .removeClass("fa-sort-up")
          .addClass("fa-sort");
      });

    if ($(e.target).hasClass("fa-sort-up")) {
      cls = "fa-sort-down";
      pcls = "fa-sort-up";
    } else if ($(e.target).hasClass("fa-sort-down")) {
      cls = "fa-sort-up";
      pcls = "fa-sort-down";
    } else {
      cls = "fa-sort-up";
    }
    $(e.target).removeClass(pcls).addClass(cls);
    sorting.orderBy = column;
    if (cls == "fa-sort-down") {
      sorting.order = "DESC";
    }

    if (this.props.sorting) {
      this.props.sorting(sorting);
    }

    console.log("sortng:::::::::::::::::::", sorting);
    this.state.order = cls;
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", this.state.order);
  };

  truncate = (input, charLimit) => {
    let limit = 15;
    if (charLimit) {
      limit = charLimit;
    }
    if (input && input.length > limit)
      return (
        <Fragment>
          <a className="tip">
            {" "}
            {input.substring(0, limit)}... <span> {input} </span>
          </a>
        </Fragment>
      );
    else return input;
  };

  render() {
    const { currentSort } = this.state;
    const { selectedTableRowIndex } = this.props;
    console.log("**********>>>>>index", selectedTableRowIndex);
    let ps = [];
    const psTmp = [];
    let totalPages =
      this.props.filteredRecords % this.state.recordsPerPage === 0
        ? Math.floor(this.props.filteredRecords / this.state.recordsPerPage)
        : Math.floor(this.props.filteredRecords / this.state.recordsPerPage) +
          1;
    let disabledNext = false;
    let disabledPrev = false;
    for (let i = 1; i <= totalPages; i++) {
      psTmp.push({ page: i, limit: this.state.recordsPerPage });
    }

    const pagging = (sk = "") => {
      let psTmp2 = deepCopy(psTmp);
      if (sk == "next") {
        psTmp2.shift();
      }
      const maxPs = 15;
      if (psTmp2.length < maxPs + 3) {
        ps = psTmp2;
      } else {
        for (let j = 0; j < maxPs; j++) {
          ps.push(psTmp2[j]);
        }

        ps.push({ page: "...", limit: this.state.recordsPerPage });
        ps.push(psTmp2[psTmp2.length - 1]);
      }
    };

    pagging();
    if (this.props.page === 1) {
      disabledPrev = true;
    }
    if (this.props.page === totalPages) {
      disabledNext = true;
    }

    const fnNextPage = (page) => {
      console.log("fnNextPage:::::::::::page", page);
      const next = page + 1;
      if (next <= totalPages) {
        this.fnGetRecords(next);
        disabledNext = false;
      } else {
        disabledNext = true;
      }
    };

    const fnPrevPage = (page) => {
      console.log("fnPrevPage :::::::::: page", page);
      const prev = page - 1;
      if (prev > 0) {
        this.fnGetRecords(prev);
        disabledPrev = false;
      } else {
        disabledPrev = true;
      }
    };

    const link = (ln, index) => {
      return (
        <a
          className={ln.cssClass + (ln.tooltip ? " tooltip_main" : "")}
          style={{ color: "#008fb5", cursor: "pointer", fontWeight: "400" }}
          onClick={() => ln.action(ln.params)}
          key={index}
        >
          {ln.tooltip ? (
            <Fragment>
              {ln.faIcon && <i className={ln.faIcon}></i>}
              {ln.ellipAndStWidth
                ? this.truncate(ln.label, ln.charLimit)
                : ln.label}
              <div className="tooltip-body">{ln.tooltip}</div>
            </Fragment>
          ) : (
            <Fragment>
              {ln.faIcon && <i className={ln.faIcon}></i>}
              {/* {ln.label && this.truncate(ln.label, ln.charLimit)} */}
              {ln.ellipAndStWidth
                ? this.truncate(ln.label, ln.charLimit)
                : ln.label}
            </Fragment>
          )}
        </a>
      );
    };

    const rating = (ln, index) => {
      return (
        <StarRatings
          rating={ln.label}
          starRatedColor="#ff9933"
          changeRating={this.changeRating}
          numberOfStars={5}
          starDimension="15px"
          starSpacing="0px"
          name="rating"
        />
      );
    };

    const button = (btn, index) => {
      // console.log('BTN ----> ', btn.action, ' --- ', typeof(action));
      return (
        <button
          type="button"
          className={
            "btn " +
            btn.cssClass +
            (btn.tooltip ? " tooltip_main" : "") +
            " pt-1 pb-1 m-0 waves-effect"
          }
          disabled={btn.disabled}
          onClick={() => btn.action(btn.params)}
          key={index}
        >
          {btn.tooltip ? (
            <Fragment>
              {btn.faIcon && <i className={btn.faIcon}></i>}
              {btn.path && (
                <img style={{ height: "25px", width: "27px" }} src={btn.path} />
              )}
              {btn.label && btn.label}
              <div className="tooltip-body">{btn.tooltip}</div>
            </Fragment>
          ) : (
            <Fragment>
              {btn.faIcon && <i className={btn.faIcon}></i>}
              {btn.path && (
                <img style={{ height: "25px", width: "27px" }} src={btn.path} />
              )}
              {btn.label && btn.label}
            </Fragment>
          )}
        </button>
      );
    };

    const td = (dr, rowIndex) => {
      let color = colorGrayLightt;
      // console.log("dr------", dr, rowIndex)
      const keys = dr ? Object.keys(dr) : [];
      return keys.map((k, index) => {
        // console.log("DRK-------------: ", dr[k], k, keys);
        if (typeof dr[k] === "object") {
          // console.log("DR: ", dr[k]);
          if (dr[k] && dr[k].actions) {
            return (
              <td
                className={
                  dr[k].cssClass ? dr[k].cssClass : ""
                  // dr[k].ellipAndStWidth === true ?
                  //   (dr[k].id==="vs_nm" || dr[k].id==="sales") ?
                  //     dr[k].cssClass+" ellipAndStWidth-custom1"
                  //     :
                  //     dr[k].cssClass+" ellipAndStWidth-custom"
                  //   :
                  //   dr[k].cssClass ? dr[k].cssClass : ""
                }
                key={index + k}
                style={
                  selectedTableRowIndex == rowIndex
                    ? { backgroundColor: color }
                    : {}
                }
                //title = { dr[k].actions[0].label }
              >
                {
                  // console.log(dr[k].actions),
                  dr[k].actions.map((act, actInd) => {
                    // console.log("act.hide===>", act.hide, ' --- ', act.id, ' --- ', act.is_active);
                    if (act.type == "button" && !act.hide) {
                      return button(act, actInd);
                    } else if (act.type == "toggle") {
                      //return toggleButton(act, actInd)
                    } else if (act.type == "link") {
                      return link(act, actInd);
                    } else if (act.type == "rating") {
                      return rating(act, actInd);
                    }
                  })
                }
              </td>
            );
          } else if (dr[k] && dr[k].chips) {
            return (
              <td
                className={
                  dr[k].cssClass ? dr[k].cssClass : ""
                  // dr[k].ellipAndStWidth === true ?
                  //   (dr[k].id==="vs_nm" || dr[k].id==="sales") ?
                  //     dr[k].cssClass+" ellipAndStWidth-custom1"
                  //     :
                  //     dr[k].cssClass+" ellipAndStWidth-custom"
                  //     :
                  //     dr[k].cssClass ? dr[k].cssClass : ""
                }
                key={index + k}
                style={
                  selectedTableRowIndex == rowIndex
                    ? { backgroundColor: color }
                    : {}
                }
              >
                {dr[k].chips.map((chip, chipIndex) => {
                  return (
                    <Fragment key={chipIndex}>
                      {chip.val && chip.val != "--" ? (
                        <span
                          className={
                            chip.cssClass
                              ? "ps-chip " + chip.cssClass
                              : "ps-chip"
                          }
                        >
                          {chip.val}
                          {chip.action ? (
                            <i
                              onClick={chip.action}
                              className="fa fa-times ml-2"
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        chip.val
                      )}
                    </Fragment>
                  );
                })}
              </td>
            );
            // return  <td className={dr[k].cssClass} key={index+k}>{dr[k].val}</td>
          } else if (dr[k] && dr[k]["type"] == "tooltip") {
            return (
              <td className={"tooltip_main " + dr[k].cssClass} key={index + k}>
                <div className="ellipsis">{dr[k].val}</div>
                {dr[k].val == "--" ? (
                  ""
                ) : (
                  <div className="tooltip-body">{dr[k].val}</div>
                )}
              </td>
            );
          } else if (dr[k] && dr[k]["type"] == "input") {
            return (
              <td className={"" + dr[k].cssClass} key={index + k}>
                <div>
                  <input
                    style={dr[k]["inputStyle"]}
                    type={dr[k]["inputType"]}
                    id={dr[k]["id"]}
                    name={dr[k]["name"]}
                    // value = { dr[k]["value"] }
                    value={this.state.voipcount}
                    placeholder={dr[k]["placeholder"]}
                    disabled={dr[k]["disabled"]}
                    hide={dr[k]["hide"]}
                    title={dr[k]["title"]}
                    // onChange = {
                    //   (e) => {
                    //       this.props.updateData(e, rowIndex, k, e.target.value )
                    //     }
                    // }

                    onChange={(e) => {
                      this.setState({ voipcount: e.target.value }, () => {
                        this.props.updateData(
                          this.state.voipcount,
                          rowIndex,
                          k
                        );
                      });
                      // dr[k].action(e, rowIndex )
                    }}
                  />
                  {dr[k]["label"]}
                </div>
              </td>
            );
          } else if (dr[k] && dr[k]["type"] == "checkbox") {
            return (
              <td className={"" + dr[k].cssClass} key={index + k}>
                <div>
                  <Checkbox
                    style={dr[k]["inputStyle"]}
                    color="primary"
                    type={dr[k]["inputType"]}
                    id={dr[k]["id"]}
                    name={dr[k]["name"]}
                    //value = { dr[k]["value"] }
                    value={this.state.hardwarecount}
                    //checked={dr[k]["value"]}
                    placeholder={dr[k]["placeholder"]}
                    disabled={dr[k]["disabled"]}
                    hide={dr[k]["hide"]}
                    title={dr[k]["title"]}
                    onChange={(e) => {
                      this.setState({ hardwarecount: e.target.value }, () => {
                        console.log("e:::::::::::", e);
                        this.props.updateHwData(
                          this.state.hardwarecount,
                          rowIndex,
                          k
                        );
                      });
                      // dr[k].action(e, rowIndex )
                    }}
                  />
                  {dr[k]["label"]}
                </div>
              </td>
            );
          } else if (dr[k] && dr[k]["type"] == "checkbox+") {
            return (
              <td className={"" + dr[k].cssClass} key={index + k}>
                <div>
                  <Checkbox
                    style={dr[k]["inputStyle"]}
                    color="primary"
                    type={dr[k]["inputType"]}
                    id={dr[k]["id"]}
                    name={dr[k]["name"]}
                    value={dr[k]["value"]}
                    checked={dr[k]["value"]}
                    placeholder={dr[k]["placeholder"]}
                    disabled={dr[k]["disabled"]}
                    hide={dr[k]["hide"]}
                    title={dr[k]["title"]}
                    onChange={(e) => {
                      console.log("e>>>>>>>>>>>>>>>>>>>>>>",e.parent)
                      this.props.simIncData(e.target, rowIndex, dr[k]["ind"]);
                      //dr[k].action(e, rowIndex )
                    }}
                  />
                  {dr[k]["label"]}
                </div>
              </td>
            );
          }

          // console.log("dr[k]dr[k]dr[k]dr[k]", dr[k]);
          return (
            <td
              className={
                dr[k].cssClass ? dr[k].cssClass : ""
                // dr[k].ellipAndStWidth === true ?
                //   (dr[k].id==="vs_nm" || dr[k].id==="sales") ?
                //     dr[k].cssClass+" ellipAndStWidth-custom1"
                //     :
                //     dr[k].cssClass+" ellipAndStWidth-custom"
                //     :
                //     dr[k].cssClass ? dr[k].cssClass : ""
              }
              key={index + k}
              style={
                selectedTableRowIndex == rowIndex
                  ? { backgroundColor: color }
                  : {}
              }
            >
              {dr[k].ellipAndStWidth
                ? this.truncate(dr[k].val, dr[k].charLimit)
                : dr[k].val}
              {/* {this.truncate( dr[k].val, dr[k].charLimit ) } */}
            </td>
          );
        } else if (typeof dr[k] === "string") {
          return (
            <td
              className="text-left"
              key={index + k}
              style={
                selectedTableRowIndex == rowIndex
                  ? { backgroundColor: color }
                  : {}
              }
            >
              {dr[k]}
            </td>
          );
        } else if (typeof dr[k] === "number") {
          return (
            <td
              className="text-center"
              key={index + k}
              style={
                selectedTableRowIndex == rowIndex
                  ? { backgroundColor: color }
                  : {}
              }
            >
              {dr[k]}
            </td>
          );
        }
      });
    };
    const dataRows = this.state.data.map((dr, ind) => (
      <tr key={ind}>{td(dr, ind)}</tr>
    ));

    console.log("DATA ROWS=====", dataRows);
    const tableCss =
      "table table-hover table-striped table-bordered table-responsive-md btn-table mt-2 mb-3";
    const tableCssWithoutStripped =
      "table table-hover table-bordered table-responsive-md btn-table mt-2 mb-3";
    return (
      <Fragment>
        <div className="row m-0 py-3">
          <div className="ps-data-table" style={{height:"370px"}}>
            <table
              className={
                selectedTableRowIndex != null
                  ? tableCssWithoutStripped
                  : tableCss
              }
              style={{ overflow: "hidden" }}
            >
              <thead>
                <tr>
                  {this.props.headers.map((h, index) => {
                    // let myKeys = Object.keys(this.props.data[0]) || [];
                    console.log("this is=h====", h.label);

                    if (typeof h === "object") {
                      // console.log('coming data',h)

                      return (
                        <th key={index} className={h.cssClass}>
                          {h.isBreak === true ? (
                            <div style={{ minWidth: "90px" }}>
                              {h.twoL ? (
                                <Fragment>
                                  <div style={{ textAlign: "center" }}>
                                    <div> {h.l1} </div>
                                    <div> {h.l2} </div>
                                  </div>
                                  {h.id === "pp" || h.id === "vs_st" ? (
                                    <i
                                      className="fa fa-info-circle tooltip-body"
                                      onClick={(e) => {
                                        this.headerInfo(e, h.id);
                                      }}
                                    ></i>
                                  ) : null}
                                  {h.label != "Action" ? (
                                    <i
                                      className="sort ml-3 float-right pointer fas fa-sort"
                                      style={{ marginTop: "-23px" }}
                                      onClick={(e) => this.sortData(e, h.id)}
                                    ></i>
                                  ) : null}
                                </Fragment>
                              ) : (
                                h.label
                              )}
                            </div>
                          ) : (
                            <Fragment>
                              {h.label}{" "}
                              {h.id === "pp" || h.id === "vs_st" ? (
                                <i
                                  className="fa fa-info-circle tooltip-body"
                                  onClick={(e) => {
                                    this.headerInfo(e, h.id);
                                  }}
                                ></i>
                              ) : null}
                              {h.label != "Action" &&
                              this.props.hideSorting != true ? (
                                <i
                                  className="sort ml-3 float-right pointer fas fa-sort"
                                  onClick={(e) => this.sortData(e, h.id)}
                                ></i>
                              ) : null}
                            </Fragment>
                          )}
                        </th>
                      );
                    }
                    // return <th key={index} className="text-left">{h} <i className="sort ml-3 float-right pointer fas fa-sort" onClick={(e)=>this.sortData(e, this.props.data[0][index].id)}></i></th>
                    return (
                      <th key={index} className="text-left">
                        {h}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {dataRows != "" ? (
                <tbody>{dataRows}</tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan={this.state.totalColumns}
                      style={{ textAlign: "center" }}
                    >
                      No Records Found
                    </td>
                  </tr>
                </tbody>
              )}
              {/* <tbody>{dataRows ?  dataRows : <tr><td>No Records Found</td></tr>}</tbody> */}
            </table>
          </div>

          {this.props.hidePagination ? (
            ""
          ) : (
            <div className="mt-2 ">
              <nav>
                <ul className="pagination pg-white">
                  <li
                    className={
                      disabledPrev ? "page-item disabled" : "page-item"
                    }
                    onClick={() => fnPrevPage(this.props.page)}
                    key="prev"
                  >
                    {/* <a className="page-link prev"><img src={leftArrow} /></a> */}
                    <a className="page-link prev" style={{ margin: "0px" }}>
                      Previous
                    </a>
                  </li>
                  {ps.map((p, psIndex) => (
                    <li
                      className={
                        "page-item" +
                        (p.page == this.props.page ? " active" : "")
                      }
                      key={psIndex}
                    >
                      {p.page == "..." ? (
                        <a className="page-link">{p.page}</a>
                      ) : (
                        <a
                          className="page-link"
                          onClick={() => this.fnGetRecords(p.page)}
                        >
                          {p.page}
                        </a>
                      )}
                    </li>
                  ))}
                  <li
                    className={
                      disabledNext ? "page-item disabled" : "page-item"
                    }
                    onClick={() => fnNextPage(this.props.page)}
                    key="next"
                  >
                    {
                      console.log("pagevalue", this.props.page)
                      /* <a className="page-link next"><img src={rightArrow} /></a> */
                    }
                    <a className="page-link next">Next</a>
                  </li>
                  <li className="page-item perPage" key="1-3">
                    <select
                      className="per-page-dropdown"
                      value={this.state.recordsPerPage}
                      onChange={this.fnSetRecordsPrePage}
                      style={{ cursor: "pointer" }}
                    >
                      {/* {this.state.recordsPerPage ? (
                        <option value={this.state.recordsPerPage}>
                          {this.state.recordsPerPage} / page
                        </option>
                      ) : (
                        ""
                      )} */}

                      {this.state.perPage.map((pr, ind) => (
                        <option value={pr} key={pr}>
                          {pr} / page
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
                <p
                  style={{
                    fontSize: "13px",
                    color: "#67757c",
                    fontWeight: "400",
                    fontFamily: "Roboto",
                  }}
                >
                  Showing {}
                  {this.props.page === 1
                    ? 1
                    : this.props.page === "..."
                    ? console.log("")
                    : this.props.page === totalPages
                    ? this.props.page * this.state.recordsPerPage -
                      this.state.recordsPerPage +
                      1
                    : (this.props.page === totalPages
                        ? this.props.filteredRecords
                        : this.props.page * this.state.recordsPerPage) -
                      this.state.recordsPerPage +
                      1}
                  &nbsp; to &nbsp;
                  {this.props.page === totalPages
                    ? this.props.filteredRecords
                    : this.props.page === "..."
                    ? console.log("oooooooooooooooooooooooooo")
                    : this.props.page * this.state.recordsPerPage}
                  &nbsp; of &nbsp;
                  {/* {dataRows.length} */}
                  {this.props.filteredRecords}
                  &nbsp; entries
                </p>
              </nav>
            </div>
          )}

          {this.state.anchorEl && this.state.popoverValue === "vs_st" ? (
            <Popover
              open={true}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item style={{ width: "100%" }}>
                  <div>
                    <TableContainer>
                      <Table
                        style={{
                          border: "1px solid #92c0e6",
                          borderBottom: "2px solid #92c0e6",
                        }}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "10px",
                                background: primaryColor,
                                textAlign: "center",
                                color: pureWhite,
                              }}
                            >
                              Status
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "10px",
                                background: primaryColor,
                                textAlign: "center",
                                borderLeft: "1px solid lightgray",
                                color: pureWhite,
                              }}
                            >
                              State
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Status.map((value) => {
                            return (
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {value.id}
                                </TableCell>

                                <TableCell
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    borderLeft: "1px solid lightgray",
                                  }}
                                >
                                  {value.value}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
            </Popover>
          ) : null}
          {this.state.anchorEl && this.state.popoverValue === "pp" ? (
            <Popover
              open={true}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item style={{ width: "100%" }}>
                  <div>
                    <TableContainer>
                      <Table
                        style={{
                          border: "1px solid #92c0e6",
                          borderBottom: "2px solid #92c0e6",
                        }}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "10px",
                                background: primaryColor,
                                textAlign: "center",
                                color: pureWhite,
                              }}
                            >
                              Probability
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "10px",
                                background: primaryColor,
                                textAlign: "center",
                                borderLeft: "1px solid lightgray",
                                color: pureWhite,
                              }}
                            >
                              State
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Probability.map((value) => {
                            return (
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {value.id}
                                </TableCell>

                                <TableCell
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "center",
                                    borderLeft: "1px solid lightgray",
                                  }}
                                >
                                  {value.value}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
            </Popover>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

DataTable.propTypes = {};

export default DataTable;
