export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const SHOW_QUOTE = "SHOW_QUOTE";
export const USER_DATA = "USER_DATA";
export const IS_ADMIN = "IS_ADMIN";
export const FORM_DATA = "FORM_DATA";
export const ACTIVE_PLAN_STATUS = "ACTIVE_PLAN_STATUS";

export const ONWER_LIST_DATA = "ONWER_LIST_DATA";

export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const ACTIVE_PLAN_DATA = "ACTIVE_PLAN_DATA";

export const SELECTED_TAB = "SELECTED_TAB";
export const SELECTED_VESSEL = "SELECTED_VESSEL";
