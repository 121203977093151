import { getSessionData } from "../managers/session/SessionManager";
import { USER_DATA } from "../constants/SessionKeys";
import moment from "moment";

export const formatDate = (dateString, isTime) => {
  var date = new Date(dateString);

  var dd = String(date.getDate()).padStart(2, "0");

  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hour = date.getHours();

  var today = yyyy + "-" + mm + "-" + dd;
  if (isTime !== undefined && isTime !== null && isTime === false) {
    return today;
  } else {
    return today + "   " + hour + ":" + minutes + ":" + seconds;
  }
};

export const formatDate2 = (dateString) => {
  var date = new Date(dateString);

  var dd = String(date.getDate()).padStart(2, "0");

  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hour = date.getHours();

  var today = mm + "/" + dd + "/" + yyyy;

  return today;
};

export const convertDate = (date, format = "DD-MMM-YYYY") => {
  return moment(new Date(date)).format(format);
};

export const getPromoPercentage = (arr) => {
  let finalArr = [];
  arr.map((obj) => {
    let a = MAPPING[obj];
    finalArr.push(a);
  });

  return finalArr;
};

export const hasPermission = (moduleName) => {
  const user = getSessionData(USER_DATA);
  let features = user && user.role && user.role.featrs ? user.role.featrs : [];
  let permissionObj = features.find((obj) => obj.name == moduleName);
  if (permissionObj) {
    if (permissionObj.prmsns == 7) {
      return { READ: true, WRITE: true, DELETE: true };
    } else if (permissionObj.prmsns == 6) {
      return { READ: true, WRITE: true, DELETE: false };
    } else if (permissionObj.prmsns == 5) {
      return { READ: true, WRITE: false, DELETE: true };
    } else if (permissionObj.prmsns == 4) {
      return { READ: true, WRITE: false, DELETE: false };
    } else {
      console.log("**Permission Not Matched");
      return { READ: false, WRITE: false, DELETE: false };
    }
  } else {
    console.log("**Module Not Matched");
    return { READ: false, WRITE: false, DELETE: false };
  }
};

export const isSalesOrOps = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "Sales" ||
    user.role.name.toString() == "OPS"
    ? true
    : false;
};

export const isOpsOrFs = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "OPS" ||
    user.role.name.toString() == "FieldService"
    ? true
    : false;
};

export const isAdmOrFin = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "Admin" ||
    user.role.name.toString() == "Super Admin" ||
    user.role.name.toString() == "Finance"
    ? true
    : false;
};

export const isAdmin = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "Admin" ||
    user.role.name.toString() == "Super Admin"
    ? true
    : false;
};

export const isSuperAdmin = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "Super Admin" ? true : false;
};

export const isDashboardRevView = () => {
  const user = getSessionData(USER_DATA);
  return user.role.name.toString() == "Super Admin" ||
    user.role.name.toString() == "Admin" ||
    user.role.name.toString() == "Sales" ||
    user.role.name.toString() == "Finance"
    ? true
    : false;
};

const MAPPING = {
  636130000: 5,
  636130001: 10,
  636130002: 15,
  636130003: 20,
  636130004: 25,
  636130005: 30,
};
