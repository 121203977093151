const Base64 = require("js-base64");

function convertToJSON7(data) {
  // console.log("d---------", data)
  return JSON.parse(Base64.decode(data));
}

function deepCopy(param) {
  return JSON.parse(JSON.stringify(param));
}

function strInitials(str) {
  let initials = "";

  if (str) {
    const strArr = str.split(" ");
    strArr.forEach(s => {
      initials += s.charAt(0);
    });
  }
  initials = initials.substring(0, 2);
  return initials;
}

function isBase64(str) {
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

function validation_custom(fieldName, value, checkErrorInFieldsList) {
  console.log("name--", fieldName);
  console.log("value--", value);
  console.log("list--", checkErrorInFieldsList);

  // let checkErrorInFieldsList = checkErrorInFieldsList

  switch (fieldName) {
    case "email":
      checkErrorInFieldsList[
        fieldName
      ] = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
      break;
    case "name":
      checkErrorInFieldsList[fieldName] = /^[A-Za-z]+( +\w+)*$/.test(value);
      break;
    case "phone":
      checkErrorInFieldsList[fieldName] = /^(\+\d{1,3}[- ]?)?\d{10}$/.test(
        value
      );
      break;
    default:
      break;
  }

  console.log("update list---", checkErrorInFieldsList);
  return checkErrorInFieldsList;
}

module.exports = {
  deepCopy,
  strInitials,
  convertToJSON: convertToJSON7,
  isBase64,
  validation_custom
  // hasPermission
};
