import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
body {
  height: 100vh;
  // 100%;
  width: 100%;
  font-family: 'Roboto' !important;
  font-size: 14px !important;
  background-color: #F0F0F7 !important;
  overflow-y: scroll !important;
}

body.fontLoaded {
  font-family: 'Roboto';
  font-size: 14px
}

#app {
  background-color: #F0F0F7 !important;
  min-height: 100%;
  min-height: 100vh;
  min-width: 100%;
  overflow-y: auto !important;
}

// button

.btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active, .show > .btn-default.dropdown-toggle {
  background-color: #008FBA !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-defaultPurple {
  background-color: #42425D !important;
  text-transform: capitalize;
}

.btn-defaultGrey {
  background-color: #757575 !important;
  text-transform: capitalize;
}

.btn-defaultBlue {
  // previous color
  // background-color: #66a8ff !important;
  // with new design
  background-color: #1976d2 !important;
  text-transform: capitalize;
}

.btn-defaultTrans {
  background-color: transparent !important;
  text-transform: capitalize;
}

.btn-defaultBlack {
  background-color: #212121 !important;
  text-transform: capitalize;
}

.btn-defaultDeepPurple {
  background-color: #9575cd !important;
  text-transform: capitalize;
}

.btn-defaultRed {
  background-color: red !important;
  text-transform: capitalize;
}

.btn-defaultLoginSignup {
  background-color: #424B5A !important;
  text-transform: capitalize;
}

.btn .fa-sm, .btn-floating .fa-sm {
  font-size: 20px !important;
}

.onLoadingAndFetching{
  position: initial;
  opacity: 0.8;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
	//background: #f2f6f8;
	background: transparent;
}

.onLoadingAndFetchingAll{
	position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
	opacity: 0.8;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	width: 100vh;
}


// Table
.custom-ctl{
  padding: 3px 8px;
  min-width: 100px;
  border-radius: 4px !important;
  outline: none;
  border: #c9c9c9 1px solid !important;
  height: 26px;
  font-size: 14px;
}
.table-filter-label{

}
.table-select-control{

}
.table-search-control{
  width: 130px;
  background: transparent;
  border: none !important;
  border-bottom: 1px #c9c9c9 solid !important;
}
.table-date-control{
  width: 120px;
  padding-right: 0;
  background: transparent;
  border: none !important;
  border-bottom: 1px #c9c9c9 solid !important;
}
.table-date-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
  padding-right: -10px;
}
.table-custom-dropdown{
  min-width: 80px;
  color: #888 !important;
  background: #f7f7f7;
  border-radius: 4px !important;
}
.btn-table-search{
  height: 30px;
  padding: 5px 7px;
}
.btn-table-search i{
  font-size: 18px;
}

.custom-table{
  font-size: 13px;
}
.custom-table thead{
  background: #f8f8fb;
}
.custom-table tbody{
  background: #fff;
}
.custom-table th,.custom-table td{
  padding: 3px 12px !important;
  font-size: 13px !important;
  line-height: 34px !important;
}

.pagination .page-item .page-link{
  padding: 6px;
  margin-left: 8px;
  color: #2B2D2F!important;
  border-radius: 3px !important;
  text-align: center;
  min-width: 25px;
  height: 25px;
  font-size: 12px;
  border: 1px solid #ddd!important;
}

.next, .prev{
  padding: 5px !important;
}
.next img, .prev img{
  height: 14px;
}

.perPage{
  margin-left: 20px;
}
.per-page-dropdown{
    height: 25px;
    border: none;
    border-radius: 3px;
    width: 85px;
    text-align: center;
    padding: 3px 0px 3px 10px;
    // box-shadow: 0px 0px 15px 0px #c4c4c4;
    font-size: 12px;
    color: #848484;
    outline: none;
}

.pagination .page-item:hover .page-link,
.pagination .page-item.active .page-link{
  background: #008fb5 !important;
  color: #ffffff !important;
}

.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: none;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle:before {
  content: 'Off';
  left: -4rem;
}
.btn-toggle:after {
  content: 'On';
  right: -4rem;
  opacity: 0.5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}

.required-field::after{
  content: ' *';
  color: #ff0000;
}

.m-6{
  margin: 3.6rem !important;
}
.ml-6{
  margin-left: 3.6rem !important;
}
.mr-6{
  margin-right: 3.6rem !important;
}
.mt-6{
  margin-top: 3.6rem !important;
}
.mb-6{
  margin-bottom: 3.6rem !important;
}
.my-6{
  margin-top: 3.6rem !important;
  margin-bottom: 3.6rem !important;
}
.mx-6{
  margin-left: 3.6rem !important;
  margin-right: 4.6rem !important;
}

.m-7{
  margin: 4.6rem !important;
}
.ml-7{
  margin-left: 4.6rem !important;
}
.mr-7{
  margin-right: 4.6rem !important;
}
.mt-7{
  margin-top: 4.6rem !important;
}
.mb-7{
  margin-bottom: 4.6rem !important;
}
.my-7{
  margin-top: 4.6rem !important;
  margin-bottom: 4.6rem !important;
}
.mx-7{
  margin-left: 4.6rem !important;
  margin-right: 4.6rem !important;
}

.btn-rounded{
	border-radius: 30px;
} 
.ps-btn-outline-info{
	color: #0040bf!important;
	border-color: #0040bf!important;
}

.ps-btn-outline-info:hover{
	color: #fff !important;
	background: #0040bf !important;
	border-color: #0040bf !important;
}

.page-content{
  margin: 15px;
	background: #fff;
	padding: 10px 10px;
	min-height: 420px;
	border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.pointer{
	cursor: pointer;
}
.row-eq-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display:         flex;
}

.ps-coll-row{
    display: flex;
    display: -ms-flexbox;
}

.coll{
    width: 100%;
	padding: 7.5px;
	padding-top: 0;
}

.coll:first-child{padding-left:0;}
.coll:last-child{padding-right:0;}



//Go back button style
.go-back{
    float: right;
    text-align: right;
    padding: 0px 0px;
    color: #1976d2 !important;
    background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
  }
  .go-back:hover{
    /* text-decoration: underline; */
 }
 .go-back:active{
	 outline:none;
 }

 .btn-rounded{
   border-radius: 50px !important;
 }

 .div-space-between {
  display: flex;
  justify-content: space-between;
 }

 .back-button-right {
   text-align:right
 }

 .btn-secondary-custom {
  background-color: #979797 !important;
  color: #fff;
 }
 .btn-secondary-custom:hover {
  background-color: #979797 !important;
  color: #fff !important;
 }
 .cursor-custom {
   cursor: auto !important;
 }
 
 .custom-class-table-heading-center {
  text-align: center !important;
 }

 .custom-popup-btn {
   padding: 6px 35px !important;
   min-width: 80px !important;
   border-radius: 5px !important;
  }

  .custom-popup-border {
    border: 4px solid #008fb5;
  } 
  
  .custom-popup-icon {
    font-size: 40px;
    margin-bottom: 20px
    color: #008fb5
  }


 .custom-class-table-heading-left {
  text-align: left !important;
  padding: 12px !important;
 }

 .modal-open .modal {
  overflow-y: hidden!important;
}
body.modal-open {
  overflow: hidden!important;
}

.modal-Custom-overfloy{
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 600px;
}

.closeIcon{
  font-size: 20px;
  cursor: pointer;
  color: #008fb5;
}

.pageHeadingStyle{
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  color: #008fb5;
  text-transform: capitalize;
  width: 90%;
  box-shadow: rgb(228, 228, 228) 0px 2px 0px;
}

.custom-button-icon-style {
  box-shadow: none !important;
  &:hover {
      box-shadow: none;
  }
  &:active {
      box-shadow: none !important;
      // box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
  &:focus {
      box-shadow: none !important;
  }
  &:visited {
      box-shadow: none !important;
  }
}
.custom-button-icon-green {
  @extend custom-button-icon-style;
  color: #50d500;
  &:hover {
      background: #e8e5df;
      color: #50d500;
  }
}

.custom-button-icon-blue {
  @extend custom-button-icon-style;
  color: rgb(0, 123, 255);
  &:hover {
      background: #e8e5df;
      color: rgb(0, 123, 255);
  }
}

.custom-button-icon-gray {
  @extend custom-button-icon-style;
  color: #979797;
  &:hover {
      background: #e8e5df;
      color: #979797;
  }
}

.custom-button-icon-blue12 {
  @extend custom-button-icon-style;
  color: #008fb5 !important;
  box-shadow: none;
  &:hover {
    background: #e8e5df;
    color: #1976d2;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12;
    box-shadow: none;
  }
}

.custom-button-icon-red {
  @extend custom-button-icon-style;
  color: #f44336 !important;
  box-shadow: none;
  &:hover {
    background: #e8e5df;
    color: #f44336;
    box-shadow: none;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12;
  }
}

.custom-icon-size {
  font-size: 13px !important;
}

.overlayDiv{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: rgba(255,255,255,0.7) !important;
}

.overlaySpan{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) !important;
}

.requiredStar{
  color: rgb(244, 67, 54) !important;
  // display: inline-flex !important;
  margin-left: 3px !important;
}


`;

export default GlobalStyle;
