import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderSpinner = props => {
  const { textAlign } = props;
  const { marginTop } = props;
  const { color } = props;
  const { height } = props;
  const { width } = props;
  return (
    <div style={{ textAlign, marginTop }}>
      <Loader type="Puff" color={color} height={parseInt(height) ? parseInt(height) : 0} width={parseInt(width) ? parseInt(width) : 0} />
    </div>
  );
};

export default LoaderSpinner;
