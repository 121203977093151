import React from "react";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { TextField } from "@material-ui/core";

const MaterialDatePicker = (props) => {
  const {
    label,
    value,
    format,
    onChange,
    minDate,
    maxDate,
    disablePast,
    disableUnderline,
    style,
    variant,
    placeholder,
    disabled,
    disableToolbar,
    inputDisabled,
  } = props;
  let customStyle = style ? style : {};
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        inputVariant={variant ? variant : "standard"}
        id="date-picker-dialog"
        label={label}
        format={format ? format : "dd-MMM-yyyy"}
        value={value ? value : ""}
        disabled={disabled ? disabled : false}
        placeholder={placeholder ? placeholder : "dd-mmm-yyyy"}
        onChange={(date) => {
          console.log(date);
          onChange(date);
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disablePast={disablePast ? disablePast : false}
        style={{ ...customStyle }}
        maxDate={maxDate ? maxDate : undefined}
        minDate={minDate ? minDate : undefined}
        // inputProps={{ color: "red" }}
        InputProps={{
          disableUnderline: disableUnderline ? disableUnderline : false,
          disableToolbar: disableToolbar ? disableToolbar : false,
          //classes: { input: { color: "red" } },
        }}
        helperText=""
        TextFieldComponent={(props) => (
          <TextField
            {...props}
            disabled={inputDisabled ? inputDisabled : false}
          />
        )}
        //minDate={minDate ? minDate : null}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MaterialDatePicker;
