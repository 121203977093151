import React, { Fragment } from 'react';
import './inputIndex.css';

const InputMdb = props => {
  // const { width, mixWidth, minWidth } = props;
  return (
    <Fragment>
      <input
        className={
          props.isValidOrInvalid ? props.isValidOrInvalid==='isValid' ? 
            'form-control is-valid InputMdb form-group' : 'form-control is-invalid InputMdb form-group'  
            : 
            'form-control InputMdb form-group'
        }
        style={ props.style }
        // style = {{width: props.width, minWidth: props.minWidth, maxWidth: props.maxWidth}}
        type={props.type}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        minLength={props.minLength}
        required={props.required}
        value={props.value}
        min={props.min}
        max={props.max}
        onKeyDown={props.preventScroll}
        onChange={props.handleChange}
        title={props.title}
      />
      <div style={{display: props.isValidOrInvalid ? 'inline-block' : 'none', marginTop:"-10px" }} 
        className= { props.isValidOrInvalid==='isValid' ? "" : "invalid-feedback" } 
        >
          {props.isValidOrInvalidMessage}
      </div>
    </Fragment>
  );
};
export default InputMdb;
