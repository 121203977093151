import React, { Fragment, Component } from "react";
import "./style.scss";
import CustomSnackbar from "../../components/snackBar";
import LogoColor from "../../assets/images/K4_Logo.png";
import LogoIcon from "../../assets/img/Logo-icon.png";
import CustomButton from "../../components/mdbreact/Buttons";
import * as ApiEndPoints from "../../constants/ApiEndPoints";
import axios from "axios";
import { MDBModal, MDBModalBody } from "mdbreact";

var Base64 = require("base-64");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      disablelogin: true,
      showSnackbar: false,
      snackbarVariant: "",
      snakBarMessage: "",
      position: "",
      handleLoginLoading: false,
      openModal: false,
      showPassword: false,
      handlepasswordState: false,
      helperTextList: {
        username: "Please enter a valid username.",
        password: "Please enter a valid password."
      },
      notErrorInFields: { username: true, password: true },
      checkAllDataCorrectNess: true,
      data: "",
      profileUrl: "",
      recaptcha: "testcaptah",
      captchaStatus: false,
      handleForgotPasswordState: false,
      // handlepasswordState1: false,
      handlepasswordState: false,
      forget_type: "",
      forget_email_phone: "",
      forget_phone: ""
    };
  }

  componentDidMount() { }

  ForgotPassword = () => {
    this.setState({ handlepasswordState: !this.state.handlepasswordState });
  };

  closeModal2 = () => {
    this.setState({ handlepasswordState: false });
  };

  openAfterForgot = e => {
    e.preventDefault();
    this.setState({ handlepasswordState: true, handlepasswordState: false });
    console.log("vjbchv");
  };

  forget_type = e => {
    this.setState({ forget_type: e.target.value });
  };

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  verifyemail = () => {
    const payload = {};

    payload.email_phone = this.state.forget_email_phone;
    console.log("hii-----------", payload);
  };

  makeid = length => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$~*&-=+";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  getShareKeyString(password) {
    // console.log("=============================>>>> password: ", password);
    const enPass = Base64.encode(JSON.stringify(password));
    let sharingKey = "";
    // console.log("===========En Pass==================>>>> ", enPass);
    let newPassword = sharingKey.concat(
      this.makeid(15),
      enPass,
      this.makeid(14)
    );
    // console.log("=============================>>>> newPassword: ", newPassword);
    let encodeString = Base64.encode(JSON.stringify(newPassword));
    // console.log("=============================>>>> encodeString: ",
    //   encodeString
    // );
    return encodeString;
  }

  senddata = async (e) => {
    // sessionStorage.setItem("islogedin", true);
    // this.props.history.push('/');

    e.preventDefault();

    this.setState({
      handleLoginLoading: true
    })

    let payload = {
      username: this.state.username,
      password: this.state.password
    };

    console.log("payload for login-----", payload)

    try {
      let res = await axios.post(ApiEndPoints.API_LOGIN, payload);
      // let res = apiResponse.data;
      console.log("apiResponse----", res);

      if (res && (res.status == 200) && (res.data.message === "OK")) {
        sessionStorage.setItem("islogedin", true);
        this.props.history.push('/');
      }
      else {
        this.setState({
          handleLoginLoading: false,
          snakBarMessage: res.data.message,
          showSnackbar: true,
          snackbarVariant: "error"
        });
      }
    } catch (err) {
      console.log("***ERR", err);
    }

  };

  handleinputchange = e => {
    const { target } = e;
    const { name, id, value } = target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let { notErrorInFields, checkAllDataCorrectNess } = this.state;
    switch (fieldName) {
      case "username":
        notErrorInFields[
          fieldName
        ] = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
        break;
      default:
        break;
    }

    if (notErrorInFields.username && notErrorInFields.password) {
      checkAllDataCorrectNess = true;
    } else {
      checkAllDataCorrectNess = false;
    }

    this.setState({
      notErrorInFields: notErrorInFields,
      checkAllDataCorrectNess
    });
  };

  routetoregister = () => {
    this.props.history.push("/register");
  };

  togglecheckbox = () => {
    this.setState({ rememberme: !this.state.rememberme }, () => { });
  };

  radioChange = e => {
    this.setState({
      accountType: e.currentTarget.value
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  onChange1 = value => {
    console.log("Captcha value:", value);
    this.setState({ recaptcha: value });
  };

  render() {
    return (
      <Fragment>
        <MDBModal isOpen={this.state.handlepasswordState} toggle={() => { }}>
          <MDBModalBody>
            <div className="text-center">
              {
                <div>
                  <div>
                    <p>
                      Enter Email/Phone No. where forgot password link will be
                      sent.
                    </p>
                    <form onSubmit={e => this.openAfterForgot(e)}>
                      <input
                        type="email_phone"
                        className="form-control"
                        name="forget_email_phone"
                        value={this.state.forget_email_phone}
                        onChange={e => this.handleinputchange(e)}
                        required
                      />
                      <input
                        type="button"
                        className="button-all"
                        value="Verify"
                        onClick={e => this.verifyemail(e)}
                      />
                      <input
                        type="button"
                        className="button-all ml-2 mt-3"
                        onClick={this.closeModal2}
                        value="Close"
                      />
                    </form>
                  </div>
                </div>
              }
            </div>
          </MDBModalBody>
        </MDBModal>

        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
          position={this.state.position}
        />
        <div className="col-12 vh-100">
          <div className="row justify-content-xl-center justify-content-lg-center justify-content-md-center justify-content-center h-100">
            <div className="col-xl-7 col-lg-7 col-md-10 col-12">
              <div className="row h-100 align-items-center">
                <div className="col-12">
                  <div className="row h-100 login-box-shadow">
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 login-box-img login-color-icon">
                      {/* <img src={LogoIcon} width="89px" /> */}
                      <img
                        src={LogoColor}
                        width="150px"
                        style={{ margin: "auto", width:"150px" }}
                      ></img>
                    </div>
                    <div className="col-12 col-sm-12 col-xs-12 col-md-7 col-lg-7 col-xl-7 p-25 align-items-center d-flex">
                      <form onSubmit={this.senddata} className="login-box">
                        <div className="form-group text-center">
                          <h3 className="h3 text-center mt-1">Login</h3>
                        </div>

                        <div className="input-group mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-user"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            id="username"
                            className="form-control inputText"
                            onChange={data => this.handleinputchange(data)}
                            name="username"
                            required
                            maxLength="50"
                            placeholder="Username"
                          />
                          <span className="underline"></span>
                          {/* <span class="floating-label1">username</span> */}
                        </div>
                        {/* {!this.state.notErrorInFields.username && (
                          <div className="validation-feedback invalid-feedback">
                            {this.state.helperTextList.username}
                          </div>
                        )} */}
                        <div className="input-group mb-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-lock"></i>
                            </span>
                          </div>
                          <input
                            type={this.state.showPassword ? "text" : "password"}
                            id="password"
                            className="form-control inputText"
                            onChange={data => this.handleinputchange(data)}
                            name="password"
                            required
                            minLength="6"
                            maxLength="30"
                            placeholder="Password"
                          />
                          <span className="underline"></span>
                          {/* <span class="floating-label1">Password</span> */}
                          {/* {!this.state.notErrorInFields.password && (
                            <div className="validation-feedback invalid-feedback">
                              {this.state.helperTextList["password"]}
                            </div>
                          )} */}
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                onClick={this.handleShowPassword}
                                title="Hide password"
                                className={
                                  this.state.showPassword
                                    ? "far fa-eye"
                                    : "far fa-eye-slash"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>

                        {/* <div className="recaptcha">
                          <ReCAPTCHA
                            sitekey="6LdPpNYUAAAAAGgLn36GjHn3_rh3LtcNbnfCJgAN"
                            onChange={this.onChange1}
                            size="normal"
                            style={{ marginTop: "15px" }}
                          />
                        </div> */}
                        {/* {this.state.recaptcha == "" &&
                        this.state.captchaStatus ? (
                          <p className="text-danger font-weight-normal">
                            Recaptcha is mandatory
                          </p>
                        ) : (
                          ""
                        )} */}
                        <div className="text-center mt-2 flex">
                          <CustomButton
                            type="submit"
                            isLoading={this.state.handleLoginLoading}
                            name="Log in"
                            className="Login"
                            // disabled={!this.state.checkAllDataCorrectNess}
                            id="login"
                            className="ButtonStyleWithBBlue"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;

