import React, { Fragment } from "react";
//import PropTypes from 'prop-types';
//import api from '../../../server/middlewares/apiMiddleWare';
import { deepCopy } from "../../utils/utils";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
//import 'jspdf-autotable';
//import { stringify } from 'querystring';
import { MDBModal, MDBModalBody } from "mdbreact";
//import { render } from 'react-testing-library';
import { TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { callPostApi, callGetApi } from "../../managers/api/ApiManager";
import {
  API_EXPORT_VESSEL_DETAILS,
  FILE_API_URL,
  API_BASE_URL,
  API_EXPORT_INVENTORY_DETAILS,
  API_EXPORT_LTE_PROVIDER_DETAILS,
} from "../../constants/ApiEndPoints";
import { getSessionData } from "../../managers/session/SessionManager";
import { USER_DATA } from "../../constants/SessionKeys";
import MaterialDatePicker from "../MaterialDatePicker";
import CustomLoader from "../../components/Loders/CustomLoader";
import CustomSnackbar from "../../components/snackBar";
import Tooltip from "@material-ui/core/Tooltip";
import {
  formatDate,
  hasPermission,
  isOpsOrFs,
  isAdmOrFin,
} from "../../constants/AppUtils";

const styles = (theme) => ({
  textfield: {
    height: 33,
    maxWidth: 115,
    maxHeight: 45,
    fontSize: 12,
    color: "#67757c",
    background: "none",
    boxShadow: "none",
  },
  textfieldInput: {
    height: 33,
    maxWidth: 90,
    maxHeight: 45,
    fontSize: 12,
    color: "#67757c",
  },
});

const image = {
  url: require("../../assets/images/exportImg.png"),
  title: "Export",
  //width: "33.3%",
};

class DataTableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: this.props.filters ? this.props.filters.searchKeyword : "",
      searchFromDate: "",
      searchToDate: "",
      searchFilterType: this.props.filters
        ? this.props.filters.searchFilterType
        : "",
      exportData: "",
      recordsPrePage: this.props.recordsPrePage,
      fileType: "",
      page: 1,
      openModalState: false,
      exportHeaders: "",
      headersCheckbox: false,
      headers: {
        headersName: "",
      },
      exportHeaders: [],
      fileTypeCheck: "",
      export: "",
      allChecked: false,
      flag: true,
      arr: [],
      selectallifallchecked: true,
      conditionValue: "",
      conditionValueInput: "",
      conditionValueBetween1: "",
      conditionValueBetween2: "",
      showLoader: false,
      showSnackbar: false,
      snackbarVariant: "",
      snakBarMessage: "",
      position: "",
    };
    this.doSearch = this.doSearch.bind(this);
    this.FileType = this.FileType.bind(this);
  }
  componentDidMount() {
    let exportHeaders = this.props.exportHeaders
      ? this.props.exportHeaders.map((h) => {
          h.selected = false;
          return h;
        })
      : [];
    this.setState({
      exportHeaders: exportHeaders,
    });
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({
    //   searchKeyword: this.props.filters.textEn,
    //   searchFromDate: this.props.filters.textEn,
    //   searchToDate: this.props.filters.textEnMax,
    //   searchFilterType: this.props.filters.category,
    //   conditionValue: this.props.filters.condition,
    //   conditionValueBetween1: this.props.filters.textEn,
    //   conditionValueBetween2: this.props.filters.textEnMax,
    //   conditionValueInput: this.props.filters.textEn,
    //   exportHeaders: nextProps.exportHeaders
    // });
  }

  setNoOfRecords = (e) => {
    let { target } = e;
    this.setState({ no_of_records: target.value });
  };

  toggle = () => {};

  closeModal = () => {
    let checkstate = this.state.exportHeaders.map((hd) => {
      if (this.state.allChecked) {
        hd.selected = false;
        this.setState({ allChecked: false });
      } else {
        this.state.exportHeaders.forEach((eh) => {
          if (eh.selected == true) {
            eh.selected = false;
          }
        });
      }
      this.setState({ arr: [] });
    });
    this.setState({
      openModalState: false,
      checkstate: checkstate,
    });
  };

  // handleChange = (e) => {
  //   let {target} = e;
  //   let checked = target.checked;
  //   let checkstate = this.state.exportHeaders.map((hd)=>{
  //       if(this.state.allChecked) {
  //           hd.selected = false;
  //           this.setState({allChecked: false});
  //       }
  //       else {
  //           hd.selected = true;
  //           this.setState({allChecked: true});
  //       }

  //   })
  //   this.setState({checkstate: checkstate})
  // }
  handleChange = (e) => {
    let { target } = e;
    let checked = target.checked;
    var exportHeaders_Lemgth = Object.keys(this.state.exportHeaders).length;
    let checkstate = this.state.exportHeaders.map((hd) => {
      if (this.state.allChecked) {
        hd.selected = false;
        this.setState({ allChecked: false });
        if (this.state.arr.length > exportHeaders_Lemgth) {
          this.state.arr.length = exportHeaders_Lemgth - 1;
        }
        this.state.arr.unshift(true);
      } else {
        hd.selected = true;
        this.setState({ allChecked: true });
        console.log("coming", this.state.arr.length, exportHeaders_Lemgth);

        if (this.state.arr.length > exportHeaders_Lemgth) {
          this.state.arr.length = exportHeaders_Lemgth - 1;
        }
        this.state.arr.unshift(false);
      }
    });
    this.setState({ checkstate: checkstate });
    console.log(this.state.checkstate);
  };

  changeColumnHandler = (e, key) => {
    let { target } = e;
    let exportHeaders = this.state.exportHeaders.map((hd) => {
      let length = Object.keys(this.state.exportHeaders).length;
      if (key == hd.key) {
        if (length > this.state.arr.length) {
          this.setState({
            arr: this.state.arr.concat(hd.selected),
          });
        } else {
          let breakCondition1 = false;
          let breakCondition2 = false;
          for (let j = 0; j < length; j++) {
            if (this.state.arr[j] != true && breakCondition1 == false) {
              this.state.arr.splice(j, 1, hd.selected);
              breakCondition1 = true;
            } else if (this.state.arr[j] == true && breakCondition2 == false) {
              this.state.arr.splice(j, 1, hd.selected);
              breakCondition2 = true;
            }
          }
        }

        if (hd.selected == true) {
          this.setState({ allChecked: false });
        }

        if (target.type == "checkbox") {
          hd[target.name] = target.checked;
        } else {
          hd.value = target.value;
        }
      }

      if (
        this.state.arr.length == length - 1 ||
        this.state.arr.length == length
      ) {
        for (let k = 0; k < length; k++) {
          if (this.state.arr[k] == true) {
            this.state.selectallifallchecked = false;
          } else {
            var dofalse = true;
            for (let i = 0; i < length; i++) {
              if (this.state.arr[i] == true) {
                dofalse = false;
              }
            }
            if (dofalse == true) {
              this.state.selectallifallchecked = true;
            }
          }
        }
        if (this.state.selectallifallchecked == true) {
          this.setState({ allChecked: true });
        } else if (this.state.selectallifallchecked == false) {
          this.setState({ allChecked: false });
        }
      }
      return hd;
    });
    this.setState({ exportHeaders: exportHeaders });
  };

  ExportFile = (event) => {
    event.preventDefault();
    if (this.state.fileTypeCheck != "Export") {
      this.doSearch(this.state.fileTypeCheck);
    }
    this.setState({ arr: [] });
  };

  async FileType(event) {
    console.log("url", this.props.url);
    if (this.props.url === "vesselmanagement") {
      this.setState({ showLoader: true });
      try {
        let res = await callGetApi(
          "EXPORT_VESSEL_DETAILS",
          "TAG_LIST_EXPORT_VESSEL_DETAILS",
          API_EXPORT_VESSEL_DETAILS
        );
        console.log("res::::::::::::", res);
        let path = res.data.filePath;
        if (res && res.status === 1) {
          if (res.data.filePath) {
            this.setState({ showLoader: false }, () => {
              //console.log("check this::::",`${FILE_API_URL}${path}`);
              window.location.href = API_BASE_URL + "uploads/" + path;
            });
          }
        } else {
          this.setState({
            showLoader: false,
            showSnackbar: true,
            snackbarVariant: "warning",
            snakBarMessage: `Network Error`,
            position: "left",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (this.props.url === "inventory") {
      this.setState({ showLoader: true });
      try {
        let res = await callGetApi(
          "EXPORT_INVENTORY_DETAILS",
          "TAG_LIST_EXPORT_INVENTORY_DETAILS",
          API_EXPORT_INVENTORY_DETAILS
        );
        console.log("res::::::::::::", res);
        let path = res.data.filePath;
        if (res && res.status === 1) {
          if (res.data.filePath) {
            this.setState({ showLoader: false }, () => {
              console.log("check this::::", `${FILE_API_URL}${path}`);
              window.location.href = API_BASE_URL + "uploads/" + path;
              //window.location.href = "http://d7.k4mobility.com/api/uploads/vessel/Vessel_Export.xlsx";
            });
          }
        } else {
          this.setState({
            showLoader: false,
            showSnackbar: true,
            snackbarVariant: "warning",
            snakBarMessage: `Network Error`,
            position: "left",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (this.props.url === "lteProvider") {
      this.setState({ showLoader: true });
      console.log("EXPORT LTE PROVIDER");
      try {
        let res = await callGetApi(
          "EXPORT_LTE_PROVIDER_DETAILS",
          "TAG_LIST_EXPORT_LTE_PROVIDER_DETAILS",
          API_EXPORT_LTE_PROVIDER_DETAILS
        );
        console.log("res::::::::::::", res);
        let path = res.data.filePath;
        if (res && res.status === 1) {
          if (res.data.filePath) {
            this.setState({ showLoader: false }, () => {
              console.log("check this::::", `${FILE_API_URL}${path}`);
              window.location.href = API_BASE_URL + "uploads/" + path;
              //window.location.href = "http://d7.k4mobility.com/api/uploads/vessel/Vessel_Export.xlsx";
            });
          }
        } else {
          this.setState({
            showLoader: false,
            showSnackbar: true,
            snackbarVariant: "warning",
            snakBarMessage: `Network Error`,
            position: "left",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleClick = (e) => {
    e.preventDefault();
  };

  fnSetFilters = () => {
    let filters;
    if (
      this.state.searchFilterType === "cr_at" ||
      this.state.searchFilterType === "clsrdt" ||
      this.state.searchFilterType == "dt_proc" ||
      this.state.searchFilterType == "dt_trns" ||
      this.state.searchFilterType === "pubh_at" ||
      this.state.searchFilterType === "lstdt" ||
      this.state.searchFilterType === "date"
    ) {
      filters = {
        textEn:
          this.state.searchFromDate != "" ? this.state.searchFromDate : "",
        textEnMax: this.state.searchToDate != "" ? this.state.searchToDate : "",
        category: this.state.searchFilterType,
        condition: this.state.conditionValue,
        type: "date",
      };
    } else if (
      this.state.searchFilterType === "pp" ||
      this.state.searchFilterType === "ex_rv" ||
      this.state.searchFilterType === "wrnty" ||
      this.state.searchFilterType === "baseLP" ||
      this.state.searchFilterType === "thrsP" ||
      this.state.searchFilterType === "bs_pr" ||
      this.state.searchFilterType === "ove_pr" ||
      this.state.searchFilterType === "tbs_pr" ||
      this.state.searchFilterType === "tove_pr" ||
      this.state.searchFilterType === "fr_min" ||
      this.state.searchFilterType === "con_len" ||
      this.state.searchFilterType === "mir_fl" ||
      this.state.searchFilterType === "mir_rl" ||
      this.state.searchFilterType === "cir_fl" ||
      this.state.searchFilterType === "cir_rl" ||
      this.state.searchFilterType === "lte" ||
      this.state.searchFilterType === "vsat" ||
      this.state.searchFilterType === "voip" ||
      this.state.searchFilterType === "hardware" ||
      this.state.searchFilterType === "minsim" ||
      this.state.searchFilterType === "maxsim"
      //this.state.searchFilterType === "quota"
    ) {
      filters = {
        textEn: this.state.conditionValueBetween1
          ? Number(this.state.conditionValueBetween1)
          : Number(this.state.conditionValueInput),
        textEnMax: this.state.conditionValueBetween2
          ? Number(this.state.conditionValueBetween2)
          : null,
        category: this.state.searchFilterType,
        condition: this.state.conditionValue,
        type: "number",
      };
    } else if (this.state.searchFilterType === "quota") {
      if (this.state.conditionValue === "unlimited") {
        filters = {
          textEn: this.state.searchKeyword,
          textEnMax: null,
          category: this.state.searchFilterType,
          condition: "eq",
          type: "number",
        };
      } else {
        filters = {
          textEn: this.state.conditionValueBetween1
            ? Number(this.state.conditionValueBetween1)
            : Number(this.state.conditionValueInput),
          textEnMax: this.state.conditionValueBetween2
            ? Number(this.state.conditionValueBetween2)
            : null,
          category: this.state.searchFilterType,
          condition: this.state.conditionValue,
          type: "number",
        };
      }
    } else {
      filters = {
        textEn: this.state.searchKeyword,
        category: this.state.searchFilterType,
      };
    }
    // console.log("check filters here:::::::::::::",filters)
    this.props.fnSetFilters(filters);
  };

  setSearchKeyword = (event) => {
    console.log("event for search::::", event);
    if (event == "") {
      if (this.props.onClear) this.props.onClear();
    }
    this.setState({ searchKeyword: event });
  };
  setSearchFromDate = (event) => {
    this.setState({ searchFromDate: event, searchToDate: "" });
    //this.searchFromDate = event.target.value;
    //console.log("search from date changed:", event.target.value)
  };

  setSearchToDate = (event) => {
    this.setState({ searchToDate: event });
    // this.searchToDate = event.target.value;
    //console.log("search To date changed:", event.target.value)
  };

  setFilterType = (event) => {
    console.log("check filter type:", event);
    if (event == "") {
      if (this.props.onClear) this.props.onClear();
    }
    if (
      event === "cr_at" ||
      event === "clsrdt" ||
      event === "pubh_at" ||
      event === "dt_trns" ||
      event === "dt_proc" ||
      event === "lstdt" ||
      event === "date"
    ) {
      this.setState({
        searchFilterType: event,
        conditionValue: "",
        conditionValueInput: "",
        conditionValueBetween1: "",
        conditionValueBetween2: "",
        searchKeyword: "",
      });
    } else if (
      event === "pp" ||
      event === "ex_rv" ||
      event === "wrnty" ||
      event === "baseLP" ||
      event === "thrsP" ||
      event === "bs_pr" ||
      event === "ove_pr" ||
      event === "tbs_pr" ||
      event === "tove_pr" ||
      event === "fr_min" ||
      event === "quota" ||
      event === "con_len" ||
      event === "mir_fl" ||
      event === "mir_rl" ||
      event === "cir_fl" ||
      event === "cir_rl" ||
      event === "lte" ||
      event === "vsat" ||
      event === "voip" ||
      event === "hardware" ||
      event === "minsim" ||
      event === "maxsim"
    ) {
      this.setState({
        searchFilterType: event,
        conditionValue: "",
        conditionValueInput: "",
        searchFromDate: "",
        searchToDate: "",
        searchKeyword: "",
      });
    } else {
      this.setState({
        searchFilterType: event,
        conditionValue: "",
        conditionValueInput: "",
        searchFromDate: "",
        searchToDate: "",
        searchKeyword: "",
        conditionValueBetween1: "",
        conditionValueBetween2: "",
      });
    }
  };

  fnSetSearchPayload = (
    limit = this.state.no_of_records ? this.state.no_of_records : 5
  ) => {
    let payload = {};
    //console.log('limititit', limit);
    if (this.props.payload) {
      payload = this.props.payload;
    }
    payload.page = this.props.page;
    payload.limit = limit;

    if (this.state.searchFilterType) {
      //setting filter type
      console.log("searchFilterType::::::::::::", this.state.searchFilterType);
      payload.filter = this.state.searchFilterType;
    }

    if (this.searchKeyword) {
      //setting searchkeyword
      if (
        this.searchFilterType == "is_active" &&
        (this.searchKeyword == "Yes" || this.searchKeyword == "yes")
      ) {
        payload.search = "1";
      } else if (
        this.searchFilterType == "is_active" &&
        (this.searchKeyword == "No" || this.searchKeyword == "no")
      ) {
        payload.search = "0";
      } else {
        payload.search = this.searchKeyword;
      }
    }
    if (this.searchFromDate) {
      payload.fromDate = this.searchFromDate;
    }
    if (this.searchToDate) {
      payload.toDate = this.searchToDate;
    }
    console.log("check fnSetSearchPayload:::::::", payload);
    return payload;
  };

  doSearch = (fileType = "none") => {
    //console.log('in do search-- ')
    let payload = this.fnSetSearchPayload();
    console.log("check payload:::::::::", payload);
    //console.log('Data table payload', payload);
    console.log("states ALLLLLLLLL:::::::::::::", this.state);
    this.fnSetFilters();
    // callPostApi(API_SEARCH_VESSEL_DETAILS,)

    // api.get(this.props.url, payload)
    //   .then(res=>{
    //     if(fileType == 'none'){ this.props.doSearch(res); }
    //     else if(fileType ==='pdf') {
    //       let headers = [];
    //         this.state.exportHeaders.forEach(eh => {
    //           let c = {};
    //           if(eh.selected == true) {
    //             c[eh] = eh.value;
    //             headers.push({header: c[eh], dataKey: eh.key});
    //           }
    //         });
    //       this.pdfExport( res.data.data, headers )
    //     } else if(fileType ==='csv' || fileType ==='xls' || fileType ==='json') {
    //   //    console.log('res.data.data', res.data.data);
    //       let body = [];
    //       res.data.data.forEach((rr,index)  => {
    //         let c = {};
    //         this.state.exportHeaders.forEach((eh, index) => {
    //           if(eh.selected == true) {
    //             c[eh.value] = rr[eh.key].replace(/=|-/g, '');
    //           }
    //         });
    //         body.push(c);
    //         console.log('ccc', c)
    //         // return false;
    //       });
    //       this.exportCsvDatafn( fileType, body );
    //     }
    //   })
    //   .catch(err=>{
    //     //console.log(err);
    //   })
  };

  exportCsvDatafn = (fileType, exportData) => {
    const data = exportData;
    let fileName = this.props.fileName || "s2ic";
    let exportType = fileType;
    exportFromJSON({
      data,
      fileName,
      exportType,
    });
    this.setState({ openModalState: false });
  };

  pdfExport = (exportData, headers) => {
    let fileName = this.props.fileName || "s2ic";
    let body = [];

    exportData.forEach((el) => {
      let b = {};
      headers.forEach((h) => {
        b[h.dataKey] = el[h.dataKey];
      });
      body.push(b);
    });

    var doc = new jsPDF("p", "pt", "a4");
    console.log("rrrrrrrrrrrrrrrrrrrr", this.props.pagetextdisplay);
    var pagetextdisplay = this.props.pagetextdisplay;
    doc.text(25, 25, pagetextdisplay);
    doc.setFontSize(10);
    doc.autoTable({
      body: body,
      columns: headers,
      styles: { cellPadding: 0.5, cellspacing: 1, fontSize: 7 },
    });
    doc.save(fileName + ".pdf");
    this.setState(function () {
      this.closeModal();
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("do validate");
      this.doSearch("none");
    }
  };

  render() {
    const { classes } = this.props;
    const userData = getSessionData(USER_DATA);
    let ehArr = this.state.exportHeaders;
    let searchType = null;
    let searchDate = null;
    let searchTypeTextField = null;
    let exportFlag = null;
    if (
      isAdmOrFin() &&
      (this.props.url === "inventory" ||
        this.props.url === "vesselmanagement" ||
        this.props.url === "lteProvider")
    ) {
      exportFlag = (
        <div className="col-xl-3 col-lg-3 col-md-3 col-12 text-right">
          {/* <button
                //disabled
                onClick={this.FileType}
                className="custom-ctl"
                style={{background:"white",minWidth:"87px"}}
                name=""
              > */}
          <Tooltip title="Export">
            <img
              src={image.url}
              onClick={this.FileType}
              className="custom-ctl"
              style={{ background: "white", minWidth: "40px" }}
              name=""
            />
          </Tooltip>
          {/* <span style={{fontSize:"0.9rem"}}>
                Export
                </span> */}
          {/* </button> */}
        </div>
      );
    }

    if (
      this.state.searchFilterType === "pp" ||
      this.state.searchFilterType === "ex_rv" ||
      this.state.searchFilterType === "wrnty" ||
      this.state.searchFilterType === "baseLP" ||
      this.state.searchFilterType === "thrsP" ||
      this.state.searchFilterType === "bs_pr" ||
      this.state.searchFilterType === "ove_pr" ||
      this.state.searchFilterType === "tbs_pr" ||
      this.state.searchFilterType === "tove_pr" ||
      this.state.searchFilterType === "fr_min" ||
      this.state.searchFilterType === "con_len" ||
      this.state.searchFilterType === "mir_fl" ||
      this.state.searchFilterType === "mir_rl" ||
      this.state.searchFilterType === "cir_fl" ||
      this.state.searchFilterType === "cir_rl" ||
      this.state.searchFilterType === "lte" ||
      this.state.searchFilterType === "vsat" ||
      this.state.searchFilterType === "voip" ||
      this.state.searchFilterType === "hardware" ||
      this.state.searchFilterType === "minsim" ||
      this.state.searchFilterType === "maxsim"
      //this.state.searchFilterType === "quota"
    ) {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.conditionValue}
            onChange={(event) =>
              this.setState({ conditionValue: event.target.value })
            }
          >
            <option value={"select"}>select</option>
            <option value={"eq"}>equal to</option>
            <option value={"less"}>less than</option>
            <option value={"great"}>greater than</option>
            <option value={"inbet"}>between</option>
          </select>
        </div>
      );
      if (
        this.state.conditionValue === "eq" ||
        this.state.conditionValue === "less" ||
        this.state.conditionValue === "great"
      ) {
        searchTypeTextField = (
          <div style={{ marginRight: "5px" }}>
            <input
              type="number"
              className="custom-ctl table-search-control mr-3"
              placeholder="Value"
              results="0"
              value={this.state.conditionValueInput}
              onChange={(event) => {
                const { value } = event.target;
                this.setState({
                  conditionValueInput: event.target.value,
                  conditionValueBetween1: "",
                  conditionValueBetween2: "",
                });
              }}
              //onKeyPress={()=>this.doSearch('none')}
            />
          </div>
        );
      } else if (this.state.conditionValue === "inbet") {
        searchTypeTextField = (
          <>
            <div style={{ marginRight: "5px" }}>
              <input
                type="number"
                className="custom-ctl table-search-control mr-1"
                placeholder="Value"
                results="0"
                value={this.state.conditionValueBetween1}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setState({
                    conditionValueBetween1: event.target.value,
                    conditionValueInput: "",
                  });
                }}
                //onKeyPress={()=>this.doSearch('none')}
              />
            </div>
            <div style={{ marginRight: "5px" }}>
              <input
                type="number"
                className="custom-ctl table-search-control mr-1"
                placeholder="Value"
                results="0"
                value={this.state.conditionValueBetween2}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setState({
                    conditionValueBetween2: event.target.value,
                    conditionValueInput: "",
                  });
                }}
                //onKeyPress={()=>this.doSearch('none')}
              />
            </div>
          </>
        );
      } else {
        searchTypeTextField = null;
      }
    } else if (this.state.searchFilterType === "sts") {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.searchKeyword}
            onChange={(event) =>
              this.setState({ searchKeyword: event.target.value })
            }
          >
            <option value={"select"}>select</option>
            <option value={"Deployed"}>Deployed</option>
            <option value={"In Warehouse"}>In Warehouse</option>
            <option value={"In Transit"}>In Transit</option>
            <option value={"Out of Service"}>Out of Service</option>
          </select>
        </div>
      );
    } else if (this.state.searchFilterType === "st") {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.searchKeyword}
            onChange={(event) =>
              this.setState({ searchKeyword: event.target.value })
            }
          >
            <option value={"select"} hidden>
              select
            </option>
            <option value={"draft"}>Draft</option>
            <option value={"active"}>Active</option>
            <option value={"archived"}>Archived</option>
          </select>
        </div>
      );
    } else if (this.state.searchFilterType === "quota") {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.conditionValue}
            onChange={(event) => {
              if (event.target.value === "unlimited") {
                this.setState({
                  searchKeyword: event.target.value,
                  conditionValue: event.target.value,
                });
              } else {
                this.setState({
                  conditionValue: event.target.value,
                  searchKeyword: "",
                });
              }
            }}
          >
            <option value={"select"}>select</option>
            <option value={"eq"}>equal to</option>
            <option value={"less"}>less than</option>
            <option value={"great"}>greater than</option>
            <option value={"inbet"}>between</option>
            <option value={"unlimited"}>Unlimited</option>
          </select>
        </div>
      );
      if (
        this.state.conditionValue === "eq" ||
        this.state.conditionValue === "less" ||
        this.state.conditionValue === "great"
      ) {
        searchTypeTextField = (
          <div style={{ marginRight: "5px" }}>
            <input
              type="number"
              className="custom-ctl table-search-control mr-3"
              placeholder="Value"
              results="0"
              value={this.state.conditionValueInput}
              onChange={(event) => {
                const { value } = event.target;
                this.setState({
                  conditionValueInput: event.target.value,
                  conditionValueBetween1: "",
                  conditionValueBetween2: "",
                });
              }}
              //onKeyPress={()=>this.doSearch('none')}
            />
          </div>
        );
      } else if (this.state.conditionValue === "inbet") {
        searchTypeTextField = (
          <>
            <div style={{ marginRight: "5px" }}>
              <input
                type="number"
                className="custom-ctl table-search-control mr-1"
                placeholder="Value"
                results="0"
                value={this.state.conditionValueBetween1}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setState({
                    conditionValueBetween1: event.target.value,
                    conditionValueInput: "",
                  });
                }}
                //onKeyPress={()=>this.doSearch('none')}
              />
            </div>
            <div style={{ marginRight: "5px" }}>
              <input
                type="number"
                className="custom-ctl table-search-control mr-1"
                placeholder="Value"
                results="0"
                value={this.state.conditionValueBetween2}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setState({
                    conditionValueBetween2: event.target.value,
                    conditionValueInput: "",
                  });
                }}
                //onKeyPress={()=>this.doSearch('none')}
              />
            </div>
          </>
        );
      }
      // else if(this.state.conditionValue === "Unlimited") {
      //   // write your code here
      //   searchType = (
      //     <div style={{ marginRight: "5px" }}>
      //       <select
      //         className="custom-ctl table-select-control mr-3"
      //         name=""
      //         value={this.state.conditionValue}
      //         onChange={event =>
      //           this.setState({ searchKeyword: this.state.conditionValue })
      //         }
      //       >
      //       </select>
      //     </div>
      //   );
      // }
      else {
        searchTypeTextField = null;
      }
    } else if (this.state.searchFilterType === "loc") {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.searchKeyword}
            onChange={(event) =>
              this.setState({ searchKeyword: event.target.value })
            }
          >
            <option value={"select"}>select</option>
            <option value={"Warehouse"}>Warehouse</option>

            <option value={"In Transit"}>In Transit</option>
          </select>
        </div>
      );
    } else if (
      this.state.searchFilterType === "clsrdt" ||
      this.state.searchFilterType === "cr_at" ||
      this.state.searchFilterType === "lstdt" ||
      this.state.searchFilterType == "dt_proc" ||
      this.state.searchFilterType == "dt_trns" ||
      this.state.searchFilterType === "pubh_at" ||
      this.state.searchFilterType === "date"
    ) {
      searchType = <div></div>;
    } else {
      searchType = (
        <div style={{ marginRight: "5px" }}>
          <input
            type="search"
            className="custom-ctl table-search-control mr-3"
            name="table-search"
            id="table-search"
            placeholder="Search..."
            results="0"
            value={this.state.searchKeyword}
            onChange={(event) => {
              const { value } = event.target;
              this.setSearchKeyword(value);
            }}
            //onKeyPress={()=>this.doSearch('none')}
          />
        </div>
      );
    }

    if (
      this.state.searchFilterType === "clsrdt" ||
      this.state.searchFilterType === "cr_at" ||
      this.state.searchFilterType === "lstdt" ||
      this.state.searchFilterType === "dt_proc" ||
      this.state.searchFilterType == "dt_trns" ||
      this.state.searchFilterType === "pubh_at" ||
      this.state.searchFilterType === "date"
    ) {
      searchDate = (
        <div style={{ marginRight: "5px" }}>
          <select
            className="custom-ctl table-select-control mr-3"
            name=""
            value={this.state.conditionValue}
            onChange={(event) =>
              this.setState({ conditionValue: event.target.value }, () => {
                this.setState({ searchFromDate: "", searchToDate: "" });
              })
            }
          >
            <option value={"select"}>select</option>
            <option value={"eq"}>equal to</option>
            <option value={"less"}>less than</option>
            <option value={"great"}>greater than</option>
            <option value={"inbet"}>between</option>
          </select>
        </div>
      );
      if (
        this.state.conditionValue === "eq" ||
        this.state.conditionValue === "less" ||
        this.state.conditionValue === "great"
      ) {
        searchTypeTextField = (
          <div style={{ marginRight: "8px" }}>
            {/* <input
              type="date"
              className="custom-ctl table-date-control mr-2"
              name="table-search"
              id="table-search"
              placeholder="2020-03-12"
              value={this.state.searchFromDate}
              //value = {'2020-Mar-24'}
              onChange={event => {
                const { value } = event.target;
                this.setSearchFromDate(value);
              }}
              dateformat="dd-MMM-yyyy"
            /> */}
            <MaterialDatePicker
              value={this.state.searchFromDate}
              disableUnderline={true}
              style={{
                marginTop: "-0.5px",
                display: "flex",
                width: "160px",
                justifyContent: "center",
                height: "33px",
                border: "1px solid #C9C9C9",
                borderRadius: "4px",
                paddingLeft: "5px",
                fontSize: "0.8rem",
                //fontColor: "#C9C9C9",
              }}
              onChange={(value) => {
                this.setSearchFromDate(value);
              }}
            />
          </div>
        );
      } else if (this.state.conditionValue === "inbet") {
        searchTypeTextField = (
          <>
            <div style={{ marginRight: "5px" }}>
              {/* <input
                type="date"
                className="custom-ctl table-date-control mr-2"
                name="table-search"
                id="table-search"
                placeholder="20/06/2019"
                value={this.state.searchFromDate}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setSearchFromDate(value);
                }}
              /> */}
              <MaterialDatePicker
                value={this.state.searchFromDate}
                disableUnderline={true}
                style={{
                  marginTop: "-0.5px",
                  display: "flex",
                  width: "160px",
                  justifyContent: "center",
                  height: "33px",
                  border: "1px solid #C9C9C9",
                  borderRadius: "4px",
                  paddingLeft: "5px",
                  fontSize: "0.8rem",
                  //fontColor: "#C9C9C9",
                }}
                onChange={(value) => {
                  this.setSearchFromDate(value);
                }}
              />
            </div>
            <label className="mr-1 pt5 to">To</label>
            <div style={{ marginRight: "5px" }}>
              {/* <input
                type="date"
                className="custom-ctl table-date-control mr-3"
                name="table-search"
                id="table-search"
                placeholder="25/07/2019"
                value={this.state.searchToDate}
                onChange={(event) => {
                  const { value } = event.target;
                  this.setSearchToDate(value);
                }}
              /> */}
              <MaterialDatePicker
                value={this.state.searchToDate}
                disableUnderline={true}
                style={{
                  marginTop: "-0.5px",
                  display: "flex",
                  width: "160px",
                  justifyContent: "center",
                  height: "33px",
                  border: "1px solid #C9C9C9",
                  borderRadius: "4px",
                  paddingLeft: "5px",
                  fontSize: "0.8rem",
                  //fontColor: "#C9C9C9",
                }}
                onChange={(value) => {
                  this.setSearchToDate(value);
                }}
              />
            </div>
          </>
        );
      }
    } else {
      searchDate = <></>;
    }

    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.openModalState}
          centered
          toggle={this.toggle}
        >
          <MDBModalBody>
            <div>
              <div>
                <h3 className="exp-heading">Select fields to export</h3>
              </div>
              <form
                className="form-horizontal text-left"
                onSubmit={this.ExportFile}
              >
                <div className="ps-flex flex-center">
                  <div>
                    <input
                      type="checkbox"
                      className="form-control"
                      name="checkall"
                      id="checkall"
                      checked={this.state.allChecked}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                  <div>
                    <p>Select All</p>
                  </div>
                </div>
                {ehArr.map((data, index) => {
                  return (
                    <div className="ps-flex flex-center" key={index}>
                      <div>
                        <input
                          type="checkbox"
                          className="form-control"
                          name="selected"
                          value="true"
                          checked={data.selected}
                          id="headersCheckbox"
                          onChange={(event) =>
                            this.changeColumnHandler(event, data.key)
                          }
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name={data.key}
                          id="headersName"
                          onChange={(event) =>
                            this.changeColumnHandler(event, data.key)
                          }
                          value={data.value}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="ps-flex flex-center">
                  <input
                    type="number"
                    placeholder="No of records to export"
                    className="form-control"
                    name="no_of_records"
                    id="no_of_records"
                    onChange={this.setNoOfRecords}
                    min="0"
                  />
                </div>
                <div className="ps-flex flex-center mt-20">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={this.closeModal}
                    className="btn btn-white"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </MDBModalBody>
        </MDBModal>
        {/* Data table filter */}
        <div
          className="col-12  ps-filter-box"
          style={{ padding: "0" }}
          tabindex="-1"
          onKeyDown={(event) => this._handleKeyDown(event)}
        >
          <div className="row">
            <div
              className={
                searchDate
                  ? "col-xl-9 col-lg-9 col-md-9 col-12 ps-flex-check"
                  : "col-xl-9 col-lg-9 col-md-9 col-12 ps-flex"
              }
            >
              <div>
                <label className="table-filter-label mr-2">Filter On: </label>
                <select
                  className="custom-ctl table-select-control mr-3"
                  name=""
                  value={this.state.searchFilterType}
                  onChange={(event) => {
                    const { value } = event.target;
                    this.setFilterType(value);
                  }}
                >
                  {this.props.searchOn.map((opt, index) => (
                    <option value={opt.name} key={index}>
                      {opt.label}
                    </option>
                  ))}
                </select>
              </div>
              {searchType}
              {searchDate}
              {searchTypeTextField}

              <div style={{ marginRight: "5px" }}>
                <button
                  className="btn btn-search search-bg btn-table-search"
                  onClick={() => this.doSearch("none")}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12 text-right">
              <select
                disabled
                onChange={this.FileType}
                className="custom-ctl table-select-control"
                name=""
              >
                <option hidden>Export</option>
                <option value="pdf">PDF</option>
                <option value="csv">CSV</option>
              </select>
            </div>  */}
            {exportFlag}
            {this.state.showLoader ? (
              <CustomLoader
                showLoader={this.state.showLoader}
                loadingText={"Please wait..."}
              />
            ) : null}
            <CustomSnackbar
              showSnackbar={this.state.showSnackbar}
              variant={this.state.snackbarVariant}
              message={this.state.snakBarMessage}
              onClose={() => this.setState({ showSnackbar: false })}
              position={this.state.position}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withStyles(styles)(DataTableFilter);
