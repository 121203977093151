export const saveSessionData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
  return true;
};

export const getSessionData = key => {
  let data = localStorage.getItem(key);
  return JSON.parse(data);
};

export const clearData = key => {
  localStorage.removeItem(key);
  return true;
};

export const clearSession = () => {
  localStorage.clear();
  return true;
};
