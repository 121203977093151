import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Grid,
    List
} from "@material-ui/core";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBJumbotron,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from "mdbreact";

import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CustomLoader from "../../../components/Loders/CustomLoader";
import { colorAlertRed } from '../../../constants/Colors';
// import MaterialDatePicker from "../../../components/MaterialDatePicker";
import InputMdb from '../../../components/mdbreact/InputTypes/TextInput'
// import Radiobox from '../../../components/inputTypes/radioBox/singleRadio'
import Moment from 'moment';
import H2 from '../../../components/H2'
import './style.scss'
import CustomSnackbar from "../../../components/snackBar/index";
// import { deepCopy } from "../../utils/utils";
import { callPostApi, callGetApi } from "../../../managers/api/ApiManager";
import { API_ADD_OR_UPDATE_VESSEL, API_GET_AVAILABLE_IPPORT_VESSEL, VPN_FILE_API } from "../../../constants/ApiEndPoints";
// import "../../../components/DataTable/style.css";
// import "../../../components/DataTable/style.scss";
// import CheckboxCus from "../../../components/inputTypes/checkBox";

import OnClickButton from '../../../components/mdbreact/Buttons';

const styles = theme => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
});

const tableCss =
    "table table-hover table-striped table-bordered table-responsive-md btn-table mt-2 mb-3";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddOrUpdateVessel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            showLoader: false,
            loadingText: "",

            showSnackbar: false,
            snackbarVariant: "",
            snakBarMessage: "",
            position: "left",

            vs_nm: "",
            vs_ip_add: "",
            port_num: "",
            vpn_log: "",
            vpn_pass: "",
            is_vpn_pass_valid: true,
            vpn_pass_type: true,
            ser_add: "52.188.68.136/tcp:5556",

            operators: [
                // {
                //     nm: "",
                //     login:"",
                //     pass:""
                // }
            ],

            devices: [
                // {
                //     nm:"",
                //     ip:"",
                //     port:"",
                //     mapped_port:""
                // }
            ],

            downloadJSONVessel: false,

            helperTextList: {
                nm: "Only letters, numbers are allowed.",
                login: "Only letters, numbers are allowed.",
                passwd: "Only letters, numbers, @, # are allowed."
            },
            // notErrorInFields: { vs_nm: true },

            disabledSave: false

        };
    }

    generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        // var symbol = "!@#$&*_";
        // String(symbol[Math.floor(Math.random()*symbol.length)])

        retVal = String(retVal) + String(String.fromCharCode(Math.floor(Math.random()*10)+48));
        return retVal;
    }

    componentDidMount() {
        console.log("coming props----", this.props)
        if (this.props && this.props.dataForUpdate && (this.props.addOrUpdate === "update" || this.props.addOrUpdate === "read")) {
            let dataForUpdate = this.props.dataForUpdate;
            
            let tempOpt = dataForUpdate.operators;
            for(let j=0; j<tempOpt.length; j++){
                tempOpt[j]["nm_isValidOrInvalid"] = /^([A-Za-z0-9]+)$/.test( tempOpt[j]["nm"] );
                tempOpt[j]["login_isValidOrInvalid"] = /^([A-Za-z0-9]+)$/.test( tempOpt[j]["login"] );
                tempOpt[j]["pass_isValidOrInvalid"] = /^([A-Za-z0-9\@\#]+)$/.test( tempOpt[j]["passwd"] );
            }
            this.setState({
                vs_nm: dataForUpdate.vs_nm,
                vs_ip_add: dataForUpdate.vs_ip_add,
                port_num: dataForUpdate.port_num,
                vpn_log: dataForUpdate.vpn_log,
                vpn_pass: dataForUpdate.vpn_pass,
                ser_add: dataForUpdate.ser_add,
                operators: tempOpt,
                // dataForUpdate.operators,
                devices: dataForUpdate.devices,
                is_vpn_pass_valid: /^([A-Za-z0-9]+)$/.test( dataForUpdate.vpn_pass )
            }, () => {
                console.log("all states-----", this.state)
                this.setState({
                    disabledSave: !(this.state.is_vpn_pass_valid)
                })
            })
        }
        else if (this.props && (this.props.addOrUpdate === "add")) {
            // console.log("aaa---", (( (this.props.vs_nm) + "-admin" ))  )
            // console.log("bbb---", (( (this.props.vs_nm) + "-admin" ).toString() ).replace(" ", "_") )
            // console.log("ccc----",  (( (this.props.vs_nm) + "-admin" ).toString() ).split(/[" "]+/).join('_') )
            
            this.setState({
                showLoader: true,
                loadingText: "Getting available ip and port... please wait",
                vs_nm: this.props.vs_nm ? this.props.vs_nm : "",
                // vpn_log: (( (this.props.vs_nm ? (this.props.vs_nm).trim() : "") + "-admin" ).toString() ).split(/[" "]+/).join('_'),
                vpn_log: (( (this.props.vs_nm ? (this.props.vs_nm).trim() : "") ).toString() ).split(/[" "]+/).join('_'),
                vpn_pass: this.generatePassword()
            })

            callGetApi(
                "Getting ip and port component",
                "Getting ip and port",
                API_GET_AVAILABLE_IPPORT_VESSEL
            )
                .then((res) => {
                    console.log("res ip port--------", res)
                    if (res && (res.status === 200) && (res.message === "OK")) {
                        this.setState({
                            showLoader: false,
                            vs_ip_add: res.data.ip,
                            port_num: res.data.port
                        })
                    }
                    else {
                        alert("Error in getting ip and port---" + JSON.stringify(res));
                        this.setState({
                            showLoader: false
                        })
                    }
                })
                .catch(err => {
                    console.log("error-----", err)
                    alert("Error in getting ip and port---" + JSON.stringify(err));
                    this.setState({
                        showLoader: false
                    })
                })
        }
    }

    handleSave = (e) => {
        let addOrUpdate = this.props.addOrUpdate
        e.preventDefault();
        console.log("On Save---------")

        this.setState({
            showLoader: true,
            loadingText: addOrUpdate === "update" ? "Updating... please wait" : "Adding... please wait"
        })

        let payload = {
            vs_nm: (this.state.vs_nm).trim(),
            vs_ip_add: this.state.vs_ip_add,
            port_num: this.state.port_num,
            vpn_log: this.state.vpn_log,
            vpn_pass: (this.state.vpn_pass).trim(),
            ser_add: this.state.ser_add,
            operators: this.state.operators,
            devices: this.state.devices
        }

        console.log("Payload 1-----", payload)

        let messageQuote = 'Vessel entry successfully created.'; let isErrorSuccessInfo = 'success';

        if (addOrUpdate === "update") {
            console.log("Updating--------")
            messageQuote = 'Vessel entry successfully updated'
            payload["type"] = "update"
            payload["_id"] = this.props.dataForUpdate._id
            payload["is_rem"] = this.props.dataForUpdate.is_rem
            payload["createdAt"] = this.props.dataForUpdate.createdAt
            // payload["updatedAt"] = this.props.dataForUpdate.updatedAt
            payload["is_rem"] = this.props.dataForUpdate.is_rem

            console.log("payload----", payload)

            callPostApi("Update vessel Componenet", "Update vessel", API_ADD_OR_UPDATE_VESSEL, payload)
                .then((res) => {
                    console.log("res---", res)
                    if (res && (res.status === 200) && (res.message === "OK")) {
                        this.setState({
                            showSnackbar: true,
                            snackbarVariant: isErrorSuccessInfo,
                            snakBarMessage: messageQuote,
                            position: this.state.position,
                            showLoader: false,
                        }, () => {
                            // this.props.updatePlanList();
                            // setTimeout(() => {
                            //     this.props.onClose();
                            // }, 1000)

                            this.props.gettAllVessels("", "refresh");
                        })
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                        alert("Error in updating vessel details---" + JSON.stringify(res));
                    }
                })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    })
                    alert(("Error in updating vessel details---" + JSON.stringify(err)));
                })
        }
        else {
            console.log("Adding--------")
            payload["type"] = "add"
            console.log("payload---------", payload);

            callPostApi("Add vessel Componenet", "Add vessel", API_ADD_OR_UPDATE_VESSEL, payload)
                .then((res) => {
                    console.log("res---", res)
                    if (res && (res.status === 200) && (res.message === "OK") ) {
                        this.setState({
                            showSnackbar: true,
                            snackbarVariant: isErrorSuccessInfo,
                            snakBarMessage: messageQuote,
                            position: this.state.position,
                            showLoader: false,
                            // downloadJSONVessel: true
                        }, () => {
                            // setTimeout(() => {
                            //     this.props.onClose();
                            //     // this.setState({
                            //     //     downloadJSONVessel: true
                            //     // })
                            // }, 1000)
                            this.props.gettAllVessels("", "refresh");
                        })
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                        alert("Error in adding vessel---" + JSON.stringify(res));
                    }
                })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    })
                    alert(("Error in adding vessel---" + JSON.stringify(err)));
                })
        }
    }

    handledatePicker = (data, name) => {
        data = Moment(new Date(data)).format("YYYY-MM-DD")
        this.setState({
            [name]: data
        })
    }

    handleChange = (e) => {
        const { name, value, id } = e.target;
        // console.log("target-----", e.target)
        if (name === "isUnlimited") {
            if (value === "true") {
                this.setState({
                    [name]: "false"
                })
            }
            else {
                this.setState({
                    [name]: "true"
                })
            }
        }
        else if(name === "vpn_pass"){
            this.setState({ [name]: value }, ()=>{
                this.validateField(name, value );
            })
        }
        else {
            this.setState({
                [name]: value
            })
        }
    }

    handleDropDown = (e, data) => {
        const { target } = e;
        let value = target.value;
        // console.log("name, val--------", data, value)
        this.setState({
            [data]: value
        })
    }

    handleSnackBarClose = () => {
        this.setState({
            finalMessage: "",
            showSnackbar: false
        });
    };

    handleAdd = (e, mapppedKey) => {
        if (mapppedKey === "operators") {
            this.setState((prevState) => ({
                operators: [...prevState.operators, { nm: '', login: "", passwd: this.generatePassword(), nm_isValidOrInvalid: true, login_isValidOrInvalid: true, pass_isValidOrInvalid: true}],
            }));
        }
        else if (mapppedKey === "devices") {
            this.setState((prevState) => ({
                devices: [...prevState.devices, { nm: '', ip: "", port: "", mapped_port: "" }],
            }));
        }
    };

    handleRem = (e, ids, mapppedKey) => {
        if (mapppedKey === "operators") {
            let arr = this.state.operators;
            if (ids > -1) {
                arr.splice(ids, 1);
            }
            this.setState({
                operators: arr
            })
        }
        else if (mapppedKey === "devices") {
            let arr = this.state.devices;
            if (ids > -1) {
                arr.splice(ids, 1);
            }
            this.setState({
                devices: arr
            })
        }
    };

    validateField = (fieldName, value, id) => {
        let { operators, disabledSave } = this.state;

        switch (fieldName) {
            case "nm":
                operators[
                    id
                ]["nm_isValidOrInvalid"] = /^([A-Za-z0-9]+)$/.test(
                    value
                );
                break;

            case "login":
                operators[
                    id
                ]["login_isValidOrInvalid"] = /^([A-Za-z0-9]+)$/.test(
                    value
                );
                break;
            case "passwd":
                operators[
                    id
                ]["pass_isValidOrInvalid"] = /^([A-Za-z0-9\@\#]+)$/.test(
                    value
                );
                break;
            case "vpn_pass":
                this.setState({
                    is_vpn_pass_valid: /^([A-Za-z0-9]+)$/.test(value),
                    disabledSave: (/^([A-Za-z0-9]+)$/.test(value)) === true ? false : true
                })
                break;
                
            default:
                break;
        }
        // console.log("operators---", operators)

        if(fieldName != "vpn_pass"){
            for(let i=0; i<operators.length; i++){
                if((operators[i].login_isValidOrInvalid) && (operators[i].nm_isValidOrInvalid) && (operators[i].pass_isValidOrInvalid) ){
                    disabledSave = false
                }
                else{
                    disabledSave = true
                    break;
                }
            }
            this.setState({
                operators, disabledSave
            });
        }
    };


    handleChange1 = (e, mapppedKey) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        const { id } = target

        if ((["nm", "login", "passwd"].includes(name)) && (mapppedKey === "operators")) {
            let operators = [...this.state.operators]
            operators[parseInt(id)][name] = value
            this.setState({ operators: operators }, ()=>{
                this.validateField(name, value, parseInt(id));
            })
        }
        else if ((["nm", "ip", "port", "mapped_port"].includes(name)) && (mapppedKey === "devices")) {
            let devices = [...this.state.devices]
            devices[parseInt(id)][name] = value
            this.setState({ devices })
        }
        else {
            this.setState({ [name]: value })
        }
    };


    downloadTextFile = (text, name) => {
        const a = document.createElement('a');
        const type = name.split(".").pop();
        a.href = URL.createObjectURL( new Blob([text], { type:`text/${type === "txt" ? "plain" : type}` }) );
        a.download = name;
        a.click();

        this.setState({
            downloadJSONVessel: false
        })
      }
      
    handleJSONDownload = (e, mapppedKey) =>{
        let downData = {
            "vpn": {
                "vs_nm": this.state.vs_nm,
                "ip_addr": this.state.vs_ip_add,
                "login": this.state.vpn_log,
                "passwd": this.state.vpn_pass,
                "server": this.state.ser_add,
                "port": this.state.port_num,
                "users": this.state.operators,
                // "devices": this.state.devices
            }
        }
        console.log("downData---", downData)

        this.downloadTextFile(JSON.stringify(downData), this.state.vs_nm + '.json')

        // var fileName = this.state.vs_nm + '.json';
        
        // // Create a blob of the data
        // var fileToSave = new Blob([JSON.stringify(downData)], {
        //     type: 'application/json',
        //     name: fileName
        // });
        
        // // Save the file
        // saveAs(fileToSave, fileName);

  
        // var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(downData));

        // var a = document.createElement('a');
        // a.href = 'data:' + data;
        // a.download = 'data.json';
        // a.innerHTML = 'download JSON';

        // var container = document.getElementById('container');
        // container.appendChild(a);

    }

    handleVPNFileDownload = (e) => {
        let payload = {
            name: this.state.vpn_log
        }

        callPostApi("Download VPN File", "VPN File", VPN_FILE_API, payload)
            .then(response => {
                console.log(response)
                let checkRes = response.toString()
                if(checkRes.indexOf('Error') >= 0){
                    alert('No VPN file exists.')
                }else
                    this.downloadTextFile(response, this.state.vpn_log + '.vpn')
            })
    }

    handleShowPassword = (name, id) =>{

        if(name === "vpn_pass"){
            this.setState({
                vpn_pass_type: !this.state.vpn_pass_type
            })
        }
    }

    render() {
        const { classes, selectedPlanObj, dataForUpdate, addOrUpdate } = this.props;
        let { operators, devices } = this.state
        // console.log("props------", this.props)

        return (
            <div>
                <Dialog
                    fullScreen
                    open={true}
                    onClose={() => { }}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: '#FFF',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <form onSubmit={this.handleSave}>
                        <AppBar className={classes.appBar}>
                            <Toolbar >
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    style={{ outline: "none" }}
                                    onClick={() => {
                                        this.props.onClose();
                                    }}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {addOrUpdate === "update"
                                        ? "Update " + this.state.vs_nm
                                        :
                                        addOrUpdate === "read" ?
                                            this.state.vs_nm
                                            :
                                            "Add " + this.props.selectedPlanObj}
                                </Typography>
                                {/* <Button
                                    style={{ outline: "none" }}
                                    color="inherit"
                                    type="submit"
                                    disabled={addOrUpdate === "read" ? true : false}
                                >
                                    {addOrUpdate === "update" ?
                                        "Update Vessel"
                                        :
                                        addOrUpdate === "read" ?
                                            ""
                                            :
                                            "Add Vessel"
                                    }
                                </Button> */}
                            </Toolbar>
                        </AppBar>
                        <br />

                        <div style={{
                            maxWidth: "80%", margin: "auto", marginTop: "10px",
                            border: "1px solid lightgray",
                            padding: "20px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px"
                        }}>

                            {addOrUpdate === "update" ?
                                <div style = {{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{ textAlign: "end", width: "600%", marginTop:"-15px",}}>
                                    <OnClickButton
                                        handleClick={(e) => { this.handleVPNFileDownload(e) }}
                                        name="Download VPN File"
                                        icon="download"
                                        className="ButtonStyleWithBlueOnHover customOperatorButton"
                                    />
                                    </div>


                                    <div style={{  textAlign: "end", width: "100%", marginTop:"-15px" }}>
                                        <OnClickButton
                                            handleClick={(e) => { this.handleJSONDownload(e, "jsonDownload") }}
                                            name="Download JSON"
                                            icon="download"
                                            className="ButtonStyleWithBlueOnHover customOperatorButton"
                                        />
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        Vessel Name
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <InputMdb
                                        type='text'
                                        name='vs_nm'
                                        id='vs_nm'
                                        value={this.state.vs_nm}
                                        autoFocus={true}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={true}
                                        style={{ marginBottom: "6px" }}
                                        // readOnly={true}
                                    // isValidOrInvalid={this.state.notErrorInFields['vs_nm'] ? '' : 'isInvalid'}
                                    // isValidOrInvalidMessage={this.state.helperTextList['vs_nm']}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        VPN Login
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <InputMdb
                                        type='text'
                                        name='vpn_log'
                                        id='vpn_log'
                                        value={this.state.vpn_log}
                                        autoFocus={false}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={true}
                                        style={{ marginBottom: "6px" }}
                                        // readOnly={true}
                                    // isValidOrInvalid={this.state.notErrorInFields['vpn_log'] ? '' : 'isInvalid'}
                                    // isValidOrInvalidMessage={this.state.helperTextList['vpn_log']}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        VPN Password
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <div>
                                    <InputMdb
                                        type='text'
                                        //{this.state.vpn_pass_type ? 'password' : 'text'}
                                        
                                        name='vpn_pass'
                                        id='vpn_pass'
                                        value={this.state.vpn_pass}
                                        autoFocus={false}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={addOrUpdate === "read" ? true : false}
                                        style={{ marginBottom: "6px" }}            
                                        isValidOrInvalid={this.state.is_vpn_pass_valid ? '' : 'isInvalid'}
                                        isValidOrInvalidMessage={this.state.helperTextList['nm']}
                                    />
                                    {/* <div className="input-group-prepend">
                                        <span className="input-group-text">
                                        <i
                                            onClick={ (e) =>{ this.handleShowPassword('vpn_pass') }}
                                            title="Hide password"
                                            className={
                                            this.state.vpn_pass_type
                                                ? "far fa-eye-slash" 
                                                : "far fa-eye"
                                            }
                                        ></i>
                                        </span>
                                    </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        Vessel IP Address
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <InputMdb
                                        type='text'
                                        name='vs_ip_add'
                                        id='vs_ip_add'
                                        value={this.state.vs_ip_add}
                                        autoFocus={false}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={true}
                                        style={{ marginBottom: "6px" }}
                                        // readOnly={true}
                                    // isValidOrInvalid={this.state.notErrorInFields['vs_ip_add'] ? '' : 'isInvalid'}
                                    // isValidOrInvalidMessage={this.state.helperTextList['vs_ip_add']}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        Port Number
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <InputMdb
                                        type='number'
                                        name='port_num'
                                        id='port_num'
                                        value={this.state.port_num}
                                        autoFocus={false}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={true}
                                        // readOnly={true}
                                        style={{ marginBottom: "6px" }}
                                    // isValidOrInvalid={this.state.notErrorInFields['port_num'] ? '' : 'isInvalid'}
                                    // isValidOrInvalidMessage={this.state.helperTextList['port_num']}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                                    <H2 style={{ marginTop: "6px", marginRight: "-20px" }}>
                                        Server Address
                                            {/* <span style={{ color: `${colorAlertRed}` }}>*</span> */}
                                    </H2>
                                    <InputMdb
                                        type='text'
                                        name='ser_add'
                                        id='ser_add'
                                        value={this.state.ser_add}
                                        autoFocus={false}
                                        placeholder=''
                                        minLength='1'
                                        handleChange={this.handleChange}
                                        title=''
                                        required={true}
                                        maxLength='100'
                                        disabled={true}
                                        // readOnly={true}
                                        style={{ marginBottom: "6px" }}
                                    // isValidOrInvalid={this.state.notErrorInFields['ser_add'] ? '' : 'isInvalid'}
                                    // isValidOrInvalidMessage={this.state.helperTextList['ser_add']}
                                    />
                                </div>
                            </div>
                            <br />

                            <div style={{
                                maxWidth: "100%", margin: "auto", marginTop: "10px",
                                border: "1px solid lightgray",
                                padding: "10px",
                                // boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px" 
                            }}>

                                <div style={{ textAlign: "end", width: "100%", }}>
                                    <OnClickButton
                                        handleClick={(e) => { this.handleAdd(e, "operators") }}
                                        name="Add Operator"
                                        icon="plus"
                                        className="ButtonStyleWithBlueOnHover customOperatorButton"
                                    />
                                </div>

                                {this.state.operators.length > 0 ?
                                    <MDBRow>
                                        <MDBCol xs="12" sm="12" md="4" lg="4" xl="4" style={{ border: "1px solid transparent" }}>
                                            <H2>
                                                Name
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </H2>
                                        </MDBCol>

                                        <MDBCol style={{ border: "1px solid transparent" }} >
                                            <H2>
                                                Login
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </H2>
                                        </MDBCol>

                                        <MDBCol style={{ border: "1px solid transparent" }}>
                                            <H2>
                                                Password
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </H2>
                                        </MDBCol>

                                        <div style={{ border: "1px solid transparent", width: "100px" }}>
                                        </div>
                                    </MDBRow>
                                    :
                                    null
                                }

                                <div>
                                    {this.state.operators.map((val, ids) => (
                                        <MDBRow style={{ marginBottom: '5px' }} key={ids}>
                                            <MDBCol xs="12" sm="12" md="4" lg="4" xl="4" >
                                                <InputMdb type="text" name="nm" id={ids.toString()} value={operators[ids].nm}
                                                    placeholder=" "
                                                    minLength={1}
                                                    handleChange={(e) => { this.handleChange1(e, "operators") }}
                                                    // title={ToltipList.sectionName}
                                                    required
                                                    maxLength={50}
                                                    isValidOrInvalid={operators[ids].nm_isValidOrInvalid ? '' : 'isInvalid'}
                                                    isValidOrInvalidMessage={this.state.helperTextList['nm']}
                                                />
                                            </MDBCol>

                                            {/* <MDBCol xs="12" sm="12" md="2" lg="2" xl="2" >
                                            </MDBCol> */}

                                            <MDBCol >
                                                <InputMdb
                                                    type="text"
                                                    name="login"
                                                    id={ids.toString()}
                                                    value={operators[ids].login}
                                                    placeholder=" "
                                                    handleChange={(e) => { this.handleChange1(e, "operators") }}
                                                    required
                                                    minLength={1}
                                                    maxLength={50}
                                                    isValidOrInvalid={operators[ids].login_isValidOrInvalid ? '' : 'isInvalid'}
                                                    isValidOrInvalidMessage={this.state.helperTextList['login']}
                                                />
                                            </MDBCol>

                                            <MDBCol>
                                                <InputMdb
                                                    type="text"
                                                    name="passwd"
                                                    id={ids.toString()}
                                                    value={operators[ids].passwd}
                                                    placeholder=""
                                                    handleChange={(e) => { this.handleChange1(e, "operators") }}
                                                    required
                                                    minLength={1}
                                                    maxLength={50}
                                                    isValidOrInvalid={operators[ids].pass_isValidOrInvalid ? '' : 'isInvalid'}
                                                    isValidOrInvalidMessage={this.state.helperTextList['passwd']}
                                                />
                                            </MDBCol>
                                            {
                                                this.state.operators.length > 0 ?
                                                    <div style={{ width: "100px", textAlign: "center", marginTop: "-5px" }}>
                                                        <OnClickButton
                                                            handleClick={(e) => { this.handleRem(e, ids, "operators") }} icon="times"
                                                            className="IconButtonWithRedOnHover custompadd"
                                                            style={{ padding: "5px" }}
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{ width: "100px" }}>
                                                    </div>
                                            }
                                        </MDBRow>
                                    ))}
                                </div>
                            </div>
                            <br />

                            
                            <div style={{textAlign:"center", marginTop:"40px", marginBottom:"25px"}}>
                                    
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#008fb5", color: "white" }}
                                    onClick={() => {
                                        this.props.onClose();
                                    }}
                                    title = ""
                                    >
                                        Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    style={{
                                        marginLeft:"40px", 
                                        backgroundColor: this.state.disabledSave ? "lightgrey" : "#008fb5", 
                                        color: "white" ,
                                        cursor: this.state.disabledSave ? "not-allowed" : "pointer"
                                    }}
                                    // onClick={this.handleSave}
                                    type = "submit"
                                    disabled = {this.state.disabledSave}
                                    >
                                        Save
                                </Button>

                                {/* <Button
                                    style={{ outline: "none" }}
                                    color="inherit"
                                    type="submit"
                                    disabled={addOrUpdate === "read" ? true : false}
                                >
                                    {addOrUpdate === "update" ?
                                        "Update Vessel"
                                        :
                                        addOrUpdate === "read" ?
                                            ""
                                            :
                                            "Add Vessel"
                                    }
                                </Button>
                                
                                <Button
                                    style={{ outline: "none" }}
                                    color="inherit"
                                    type="submit"
                                    disabled={addOrUpdate === "read" ? true : false}
                                >
                                    {addOrUpdate === "update" ?
                                        "Update Vessel"
                                        :
                                        addOrUpdate === "read" ?
                                            ""
                                            :
                                            "Add Vessel"
                                    }
                                </Button> */}

                            </div>
                        </div>
                    </form>
                </Dialog>

                <MDBModal isOpen={this.state.downloadJSONVessel} centered>
                    <MDBModalBody>
                        <div className="col-12 p-3 text-center custom-popup-border">
                        <i className=" fa fa-question-circle custom-popup-icon"></i>
                        <h4 className="mb-4 mt-4">
                            {/* {this.state.openModalText} */}
                            Do you want to download the Vessel JSON ?
                            {/* <b>{this.state.vs_nm}</b> does not exist. Click <b>Yes</b> to add a new Vessel with this name. */}
                        </h4>
                        <button
                            type="button"
                            className="btn btn-primary custom-popup-btn"
                            onClick={(e) => { this.handleJSONDownload(e, "jsonDownload") }}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-white custom-popup-btn"
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            No
                        </button>
                        </div>
                    </MDBModalBody>
                </MDBModal>


                {this.state.showLoader ? (
                    <CustomLoader
                        showLoader={this.state.showLoader}
                        loadingText={this.state.loadingText}
                    />
                ) : null}

                <CustomSnackbar
                    showSnackbar={this.state.showSnackbar}
                    variant={this.state.snackbarVariant}
                    message={this.state.snakBarMessage}
                    onClose={this.handleSnackBarClose}
                    position={this.state.position}
                />
            </div >
        );
    }
}

export default withStyles(styles)(AddOrUpdateVessel);


{/* <div style={{
    maxWidth: "100%", margin: "auto", marginTop: "10px",
    border: "1px solid lightgray",
    padding: "10px",
}}>

    <div style={{ textAlign: "end", width: "100%", }}>
        <OnClickButton
            handleClick={(e) => { this.handleAdd(e, "devices") }}
            name="Add Device"
            icon="plus"
            className="ButtonStyleWithBlueOnHover customOperatorButton"
        />
    </div>

    {this.state.devices.length > 0 ?
        <MDBRow>
            <MDBCol xs="12" sm="12" md="4" lg="4" xl="4" style={{ border: "1px solid transparent" }}>
                <H2>
                    Name
                </H2>
            </MDBCol>

            <MDBCol style={{ border: "1px solid transparent" }} >
                <H2>
                    IP
                </H2>
            </MDBCol>

            <MDBCol style={{ border: "1px solid transparent" }}>
                <H2>
                    Port
                </H2>
            </MDBCol>

            <MDBCol style={{ border: "1px solid transparent" }}>
                <H2>
                    Mapped Port
                </H2>
            </MDBCol>
            <div style={{ border: "1px solid transparent", width: "100px" }}>
            </div>
        </MDBRow>
        :
        null
    }


    <div>
        {this.state.devices.map((val, ids) => (
            <MDBRow style={{ marginBottom: '5px' }} key={ids}>
                <MDBCol xs="12" sm="12" md="4" lg="4" xl="4" >
                    <InputMdb type="text" name="nm" id={ids.toString()} value={devices[ids].nm}
                        placeholder=" "
                        minLength={1}
                        handleChange={(e) => { this.handleChange1(e, "devices") }}
                        required
                        maxLength={50}
                    />
                </MDBCol>

             

                <MDBCol >
                    <InputMdb
                        type="text"
                        name="ip"
                        id={ids.toString()}
                        value={devices[ids].ip}
                        placeholder=" "
                        handleChange={(e) => { this.handleChange1(e, "devices") }}
                        required
                        minLength={1}
                        maxLength={50}
                    />
                </MDBCol>

                <MDBCol>
                    <InputMdb
                        type="number"
                        name="port"
                        id={ids.toString()}
                        value={devices[ids].port}
                        placeholder=""
                        handleChange={(e) => { this.handleChange1(e, "devices") }}
                        required
                        minLength={1}
                        maxLength={50}
                    />
                </MDBCol>

                <MDBCol>
                    <InputMdb
                        type="number"
                        name="mapped_port"
                        id={ids.toString()}
                        value={devices[ids].mapped_port}
                        placeholder=""
                        handleChange={(e) => { this.handleChange1(e, "devices") }}
                        required
                        minLength={1}
                        maxLength={50}
                    />
                </MDBCol>
                {
                    this.state.devices.length > 0 ?
                        <div style={{ width: "100px", textAlign: "center", marginTop: "-5px" }}>
                            <OnClickButton
                                handleClick={(e) => { this.handleRem(e, ids, "devices") }} icon="times"
                                className="IconButtonWithRedOnHover custompadd"
                                style={{ padding: "5px" }}
                            />
                        </div>
                        :
                        <div style={{ width: "100px" }}>
                        </div>
                }
            </MDBRow>
        ))}
    </div>

</div> */}