export const logData = (key, value) => {
  console.log(key, value);
};

export const trackApiLog = (
  component = "",
  tag = "",
  url = "",
  body = {},
  response = null,
  error = null
) => {
  if (response != null) {
    let json = { tag, url, response };
    console.log(json);
  } else if (error != null) {
    let json = { tag, url, error };
    console.log(json);
  } else {
    let json = { component, tag, url, body };
    console.log(json);
  }
};

export const trackSessionLog = (
  component = "",
  sessionKey = "",
  sessionData = {},
  isError = "false"
) => {
  if (isError) {
    let json = { component, sessionKey, sessionData };
    console.log("Error Saving Data::", json);
  } else {
    let json = { component, sessionKey, err: sessionData };
    console.log("Data Saved::", json);
  }
};
